import { CellText, CellSelect, CellDuration } from 'app/components/Table/Cells';
import { updateActivity } from 'app/store/reducers/reduxActivities';
import { map, invert } from 'lodash';
export default ({ deleteActivitiesAction, projects, config, isFromMyHours, dispatch }) => [
    {
        id: 'columns',
        Header: ' ',
        columns: [
            CellText({
                id: 'date',
                Header: 'Date',
                actions: (instance, cell) => [deleteActivitiesAction(instance, cell)],
                inputProps: { type: 'date' },
                readOnly: false,
                sortingKey: 'date',
                accessor: 'date',
                save: {
                    resource: 'activities',
                    formatData: (item, value, cell, instance, type) => {
                        if (Array.isArray(value))
                            return value;
                        return null;
                    },
                },
            }),
            CellDuration({
                id: 'duration',
                Header: 'Duration',
                accessor: 'duration',
                sortingKey: 'duration',
                actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                readOnly: false,
                durationProps: {
                    hide: { days: true },
                },
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { duration: newValue })),
                },
            }),
            CellText({
                id: 'comment',
                Header: 'Activity name',
                accessor: 'comment',
                actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                readOnly: false,
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { comment: newValue })),
                },
            }),
            CellSelect({
                id: 'activityType',
                Header: 'Activity type',
                actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                sortingKey: 'activityType',
                readOnly: false,
                accessor: (item) => {
                    if (!item || !item.activityType)
                        return null;
                    return config.ACTIVITY_TYPES[item.activityType];
                },
                options: () => map(config.ACTIVITY_TYPES, (label) => ({
                    label,
                    value: label,
                })),
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { activityType: invert(config.ACTIVITY_TYPES)[newValue] })),
                },
            }),
            CellSelect({
                id: 'project',
                Header: 'On project',
                actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                sortingKey: 'project',
                readOnly: false,
                options: () => [{ label: '-', value: null }].concat(projects.map((project) => ({ label: project === null || project === void 0 ? void 0 : project.name, value: project === null || project === void 0 ? void 0 : project.id }))),
                accessor: 'project',
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { project: newValue })),
                },
            }),
        ],
    },
];
