import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Redirect, Switch } from 'react-router-dom';
import moment from 'moment';
import { MyHours } from 'app/pages/MyHours/MyHours';
import { MyAllotmentHours } from 'app/pages/MyHours/MyAllotmentHours';
import Notifications from 'app/pages/Notifications/Notifications.jsx';
import Projects from 'app/pages/Project/ProjectsHomePage/Projects';
import Project from 'app/pages/Project';
import Admin from 'app/pages/Admin/Admin';
import { NotFound } from 'app/components/NotFound/NotFound';
import AssetDetail from 'app/containers/Assets/AssetDetail/AssetDetailPage';
import WikiFallback from 'app/pages/AssetsWiki/WikiFallback.jsx';
import { Wiki } from 'app/pages/AssetsWiki/AssetsWiki.jsx';
import { MyTasks } from 'app/pages/MyTasks/MyTasks.jsx';
import HR from 'app/pages/HR';
import OrganizationChart from 'app/pages/OrganizationChart/OrganizationChart';
import OrganizationChartDetail from 'app/pages/OrganizationChart/OrganizationChartDetail';
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js';
import Errors from 'app/components/Error/Error.jsx';
import TestErrors from './TestErrors';
export default function () {
    const hoursFormatView = userPrefLocalResource.getData('hoursFormatView') || 'month';
    return (_jsx(Errors, { children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/projects", component: Projects }), _jsx(Route, { path: "/projects/:projectId", component: Project }), _jsx(Route, { path: "/wiki/:articleId?", component: WikiFallback }), _jsx(Route, { path: "/assets-wiki/:assetId?/:articleId?/:articleVersion?", component: Wiki }), _jsx(Route, { exact: true, path: "/assets", component: AssetDetail }), _jsx(Route, { exact: true, path: "/testErrors", component: TestErrors }), _jsx(Route, { exact: true, path: "/my-hours", render: ({ match }) => (_jsx(Redirect, { to: `${match.url}/${hoursFormatView}/${moment().format(hoursFormatView === 'month' ? 'MM-YYYY' : 'w-YYYY')}` })) }), _jsx(Route, { path: "/my-hours/:formatView/:selectedWeek", component: MyHours }), _jsx(Route, { exact: true, path: "/my-allotment-hours", render: ({ match }) => (_jsx(Redirect, { to: `${match.url}/${hoursFormatView}/${moment().format(hoursFormatView === 'month' ? 'MM-YYYY' : 'w-YYYY')}` })) }), _jsx(Route, { path: "/my-allotment-hours/:formatView/:selectedWeek", component: MyAllotmentHours }), _jsx(Route, { path: "/notifications", component: Notifications }), _jsx(Route, { path: "/admin", component: Admin }), _jsx(Route, { exact: true, path: "/", render: () => _jsx(Redirect, { to: "/projects" }) }), _jsx(Route, { path: "/my-tasks", component: MyTasks }), _jsx(Route, { path: "/hr", component: HR }), _jsx(Route, { exact: true, path: "/organization-chart", component: OrganizationChart }), _jsx(Route, { path: "/organization-chart/:personId", component: OrganizationChartDetail }), _jsx(Route, { path: "*", component: NotFound })] }) }));
}
