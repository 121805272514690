import cookies from 'js-cookie'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { getImpersonificationUser } from 'app/pages/Admin/Impersonification/impersonificationUser.ts'
import settings from '../settings/index'

window.OVM_PROJECT_HEADER = 'X-OVM-PERMISSION-PROJECT'
window.OVM_IMPERSONNATE_HEADER = 'X-OVM-IMPERSONATE'

class Authorization {
  /**
   * @param object headers
   *
   * @return object
   */
  static getAuthHeaders() {
    let headers = {}

    const token = cookies.get(settings.cookieToken)

    if (token) {
      headers.Authorization = `Token ${token}`
    }

    headers = {
      ...headers,
      ...Authorization.getAuthHeadersProject(),
      ...Authorization.getAuthHeadersImpersonification(),
    }

    return headers
  }

  /**
   * @return object
   */
  static getAuthHeadersProject(projectId = getProjectIdFromURL()) {
    if (projectId) {
      return {
        [window.OVM_PROJECT_HEADER]: projectId || '',
      }
    }

    return null
  }

  /**
   * @return object
   */
  static getAuthHeadersImpersonification() {
    const impersonator = getImpersonificationUser()

    if (!impersonator) return null
    return {
      [window.OVM_IMPERSONNATE_HEADER]: impersonator.id,
    }
  }

  static getImpersonator() {
    return getImpersonificationUser()
  }
}

export default Authorization
