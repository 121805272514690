var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { map, find, capitalize, sortBy } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { MUISelect } from 'app/components/Form/Select/MUISelect.jsx';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ResizableCol } from 'app/components/ResizableContainer';
import resources from 'app/store/resources';
import history from 'app/main/routerHistory';
import { ToggleButton, ToggleButtonGroup } from 'app/components/Form';
import { getResources } from 'app/store/selectors/getResources';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import { MyHoursCalendar } from 'app/containers/MyHoursCalendar/MyHoursCalendar.jsx';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { assetIcons } from 'app/components/Icons/assetsIcons.js';
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings';
import TableTimeRecapLeaves from './TableTimeReacapLeaves/TableTimeRecapLeaves';
import { TableTimeRecap, tableId as timeRecapTableId } from './TableTimeRecap.jsx';
import classes from './TimeRecap.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import store from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { groupsByProjectSelector } from 'app/store/selectors/assets';
import { fetchTaskTypes } from 'app/store/reducers/taskTypes';
import TableTimeRecapActivities from './TableTimeRecapActivities/TableTimeRecapActivities';
const TimeRecap = (props) => {
    const { allotment } = props;
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const { projectId, month, resourceId } = params;
    const { selectedUser, selectedDay: _selectedDay, selectedRow, userSearch, } = queryString.parse((location.search || '').replace('?', ''));
    const userSearchData = userSearch ? getResources(store.getState(), 'assets', userSearch) : undefined;
    const selectedUserInst = getResources(store.getState(), 'assets', selectedUser);
    const { projects } = useSelector((state) => state.user);
    const [reloadActivitiesKey, setReloadActivitiesKey] = useState();
    const [checked, setChecked] = useState(false);
    const [showQuickLook, setShowQuickLook] = useState(getUserSettings(`${timeRecapTableId}-showQuickLook`));
    const [searchedName, setSearchedName] = useState('');
    const groups = useSelector(groupsByProjectSelector(projectId));
    const reloadActivities = useCallback(() => setReloadActivitiesKey(String(Date.now())), [setReloadActivitiesKey]);
    const date = useMemo(() => moment(month, 'MM-YYYY'), [month]);
    const selectedDay = useMemo(() => {
        if (!_selectedDay)
            return null;
        return moment(month, 'MM-YYYY').date(Number(_selectedDay));
    }, [_selectedDay, month]);
    const tableKey = useMemo(() => `${month}-${projectId || ''}-${resourceId || ''}${checked ? '-hideEmpty' : ''}${String(showQuickLook)}`, [month, projectId, resourceId, checked, showQuickLook]);
    const selectedGroup = useMemo(() => { var _a; return (_a = find(groups, (group) => group.name === 'all')) === null || _a === void 0 ? void 0 : _a.id; }, [groups]);
    const tableHoursTools = useRef();
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (projectId && projectId !== 'all') {
                yield resources.assets.fetchGroupsByProject(projectId);
            }
        }))();
    }, [projectId]);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(fetchTaskTypes());
            yield resources.progressionStatus.fetchAll();
            if (userSearch) {
                yield resources.assets.fetchOne(userSearch);
            }
        }))();
    }, []);
    useEffect(() => {
        let title = capitalize(date.format('MMMM'));
        if (selectedUserInst === null || selectedUserInst === void 0 ? void 0 : selectedUserInst.name)
            title = `${title} - ${selectedUserInst.name}`;
        if (selectedDay === null || selectedDay === void 0 ? void 0 : selectedDay.format)
            title = `${selectedDay.format('D')} ${title}`;
        documentTitle(title);
    }, [date, selectedUserInst, selectedDay]);
    function hideEmpty() {
        setChecked(!checked);
    }
    function onChangeLocation(newParams) {
        const updatedParams = Object.assign(Object.assign({}, params), newParams);
        const { resourceId, projectId, month } = updatedParams;
        history.pushWithQuery({ selectedDay: undefined, selectedUser: undefined, selectedRow: undefined }, `/hr/${!allotment ? 'time-recap' : 'allotment-time-recap'}/${month}/${projectId ? `${projectId}/${resourceId || ''}` : ''}`);
    }
    const toolbar = useMemo(() => {
        var _a;
        const { projectId, resourceId, month } = params;
        const selectedMonth = moment(month, 'MM-YYYY');
        const selectedGroup = (_a = find(groups, (group) => group.name === 'all')) === null || _a === void 0 ? void 0 : _a.id;
        return (_jsxs("div", { className: `${classes.toolbar} fullWidth flex row noWrap alignCenter`, children: [_jsx(PopupState, { variant: "popover", popupId: "calendar", children: (popupState) => (_jsxs(_Fragment, { children: [_jsxs(ToggleButtonGroup, { className: "marginRight10", children: [_jsx(ToggleButton, { onClick: () => onChangeLocation({
                                            month: moment(selectedMonth).subtract(1, 'month').format('MM-YYYY'),
                                        }), children: _jsx(FontIcon, { icon: "fas-chevron-left" }) }), _jsx(ToggleButton, Object.assign({ icon: "fas-calendar" }, bindTrigger(popupState), { children: _jsx("span", { className: "capitalize", children: selectedMonth.format('MMMM YYYY') }) })), _jsx(ToggleButton, { onClick: () => onChangeLocation({
                                            month: moment(selectedMonth).add(1, 'month').format('MM-YYYY'),
                                        }), children: _jsx(FontIcon, { icon: "fas-chevron-right" }) })] }), _jsx(Popover, Object.assign({}, bindPopover(popupState), { anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }, transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                }, children: _jsx(MyHoursCalendar, { display: "month", selectedWeek: moment(month, 'MM-YYYY'), onSelectMonth: (month) => onChangeLocation({ month: month.format('MM-YYYY') }) }) }))] })) }), _jsx(MUISelect, { options: [{ label: 'All projects', value: 'all' }].concat(sortBy(map(projects, (project) => ({
                        value: project.id,
                        label: project.name,
                    })), [(o) => o.label.toLowerCase()])), style: { width: 180 }, value: projectId, placeholder: "Project", onChange: (value) => {
                        const selectedProjectId = value.value;
                        // @ts-ignore
                        onChangeLocation(Object.assign({ projectId: String(selectedProjectId) }, (selectedProjectId === 'all' || selectedProjectId !== projectId ? { resourceId: null } : {})));
                    } }), _jsx(MUISelect, { style: { width: 150 }, options: map(groups, (group) => ({
                        value: group.id,
                        label: group.name,
                    })), disabled: projectId === 'all', value: projectId !== 'all' ? resourceId || selectedGroup : undefined, placeholder: "Group", onChange: (data) => {
                        return onChangeLocation(Object.assign({ resourceId: String(data.value) }, (data.data && data.data.project ? { projectId: data.data.project } : {})));
                    } }, projectId), _jsxs(ToggleButtonGroup, { children: [_jsx(ToggleButton, { selected: checked, onClick: hideEmpty, icon: "fas-eye-slash", tooltip: "Hide empty rows" }), _jsx(ToggleButton, { selected: showQuickLook, onClick: () => setShowQuickLook((val) => {
                                setUserSettings(`${timeRecapTableId}-showQuickLook`, !val);
                                return !val;
                            }), icon: "fas-table", tooltip: "Show activities overview (may be slow)" })] })] }));
    }, [groups, projects, showQuickLook]);
    const ToolbarFilters = useMemo(() => {
        return (_jsxs("div", { className: "flex alignCenter marginLeftAuto", children: [_jsx(AssetsSelect, { value: userSearchData
                        ? { label: userSearchData.name, value: userSearchData.id, icon: assetIcons(userSearchData.assetType) }
                        : null, style: { marginRight: 5, maxWidth: 250, width: 200, marginLeft: 'auto' }, assetTypes: ['us'], placeholder: userSearchData ? userSearchData.name : 'Search user...', options: userSearchData
                        ? [{ label: userSearchData.name, value: userSearchData.id, icon: assetIcons(userSearchData.assetType) }]
                        : undefined, onChange: (data) => {
                        setSearchedName(data.label);
                        history.pushWithQuery({
                            userSearch: data.value,
                            selectedDay: undefined,
                            selectedUser: undefined,
                            selectedRow: undefined,
                        });
                    } }), _jsx(IconButton, { onClick: (event) => {
                        setSearchedName('');
                        history.pushWithQuery({
                            userSearch: undefined,
                            selectedDay: undefined,
                            selectedUser: undefined,
                            selectedRow: undefined,
                        });
                    }, children: _jsx(FontIcon, { icon: "fas-times-circle", style: { fontSize: 18 } }) })] }));
    }, [userSearchData]);
    return (_jsx("div", { className: "fullWidth fullHeight", "data-testid": "page-timerecap", children: _jsx("div", { className: "flex fullHeight", children: _jsx(ResizableCol, { defaultLeftSize: 2, optionName: "TimeRecapVertResizing", disabled: undefined, left: _jsx(TableTimeRecap, { month: month, allotment: allotment, resourceId: resourceId || selectedGroup, reloadActivities: reloadActivities, hideEmpty: checked, ExtendsToolbar: toolbar, ToolbarFilters: ToolbarFilters, projectId: projectId, userSearch: userSearchData, searchedName: searchedName, showQuickLook: showQuickLook, getTools: (tools) => {
                        tableHoursTools.current = tools;
                    }, selectedRow: selectedRow, selectedUser: selectedUser, selectedDay: _selectedDay }, tableKey), right: selectedDay && selectedUser ? (_jsxs("div", { className: classes.timeRecapUserDate, children: [_jsx(TableTimeRecapActivities, { type: selectedRow, allotment: allotment, userId: selectedUser, date: selectedDay, tableHoursTools: tableHoursTools.current, projects: projects }), _jsx(TableTimeRecapLeaves, { userId: selectedUser, date: selectedDay, tableHoursTools: tableHoursTools.current })] })) : null }) }) }));
};
export default TimeRecap;
