import { CellText, CellSelect, CellStatus, CellAssetsSelect, CellLink, CellDuration } from 'app/components/Table/Cells';
import { map } from 'lodash';
import { updateActivity } from 'app/store/reducers/reduxActivities';
import { router } from 'app/containers/Assets/AssetDetail/router';
export default ({ projects, defaultActions, activityTypes, progressionStatus, isFromMyHours, dispatch }) => [
    {
        Header: 'Activities',
        id: 'activities',
        columns: [
            CellDuration({
                Header: 'Duration',
                id: 'duration',
                noFilterOption: true,
                readOnly: false,
                accessor: (task) => task && task.duration,
                width: 110,
                actions: (instance, cell) => ['edit', 'separator', ...defaultActions(instance, cell)],
                durationProps: { hide: { days: true } },
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { duration: newValue })),
                },
            }),
        ],
    },
    {
        Header: 'Custom activities',
        id: 'task',
        columns: [
            CellSelect({
                id: 'reason',
                Header: 'Reason',
                hiddenable: true,
                noFilterOption: true,
                actions: (instance, cell) => {
                    var _a, _b;
                    if (!((_b = (_a = cell.row) === null || _a === void 0 ? void 0 : _a.original) === null || _b === void 0 ? void 0 : _b.activityType))
                        return defaultActions(instance, cell);
                    return ['edit', ...defaultActions(instance, cell)];
                },
                width: 110,
                options: () => map(activityTypes, (label, value) => ({ label, value })),
                accessor: 'activityType',
                readOnly: false,
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { activityType: newValue })),
                },
            }),
            CellText({
                id: 'comment',
                Header: 'Comment',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                actions: (instance, cell) => {
                    var _a, _b;
                    if (!((_b = (_a = cell.row) === null || _a === void 0 ? void 0 : _a.original) === null || _b === void 0 ? void 0 : _b.activityType))
                        return defaultActions(instance, cell);
                    return ['edit', ...defaultActions(instance, cell)];
                },
                accessor: 'comment',
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateActivity({ activities: values, isFromMyHours })),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { comment: newValue })),
                },
                width: 250,
            }),
        ],
    },
    {
        Header: 'Asset',
        id: 'asset',
        columns: [
            CellLink({
                Header: 'Name',
                id: 'name',
                showRemoved: true,
                actions: defaultActions,
                readOnly: false,
                width: 150,
                onClick: ({ original }) => {
                    const { taskInst = { assetInst: {} } } = original;
                    return router.goTo('index', { assetId: taskInst.assetInst.id }, { rightPanel: true });
                },
                accessor: (item) => {
                    const { taskInst = { assetInst: {} } } = item;
                    return taskInst && taskInst.assetInst.name;
                },
            }),
            CellAssetsSelect({
                Header: 'Project',
                id: 'project',
                placeholder: '',
                hiddenable: true,
                showIcon: false,
                readOnly: false,
                width: 170,
                actions: defaultActions,
                accessor: (item) => {
                    if (!item || !item.project)
                        return undefined;
                    return projects === null || projects === void 0 ? void 0 : projects.find((project) => project.id === item.project);
                },
            }),
            CellAssetsSelect({
                Header: 'Asset parent',
                placeholder: '',
                hiddenable: true,
                showIcon: true,
                id: 'parent',
                width: 170,
                noFilterOption: true,
                actions: defaultActions,
                readOnly: false,
                accessor: (item) => {
                    if (!item || !item.taskInst || !item.taskInst.assetParentInst)
                        return undefined;
                    const { assetParentInst } = item.taskInst;
                    return assetParentInst;
                },
            }),
            CellText({
                Header: 'Step',
                id: 'step',
                hiddenable: true,
                width: 100,
                noFilterOption: true,
                actions: defaultActions,
                readOnly: false,
                accessor: (item) => {
                    const { taskInst = {} } = item;
                    return taskInst && taskInst.stepInst && taskInst.stepInst.name;
                },
            }),
            CellStatus({
                id: 'status',
                Header: 'Status',
                hiddenable: true,
                readOnly: false,
                actions: defaultActions,
                noFilterOption: true,
                accessor: 'taskInst.status',
                progressionStatus,
            }),
        ],
    },
];
