var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { deleteDataFomCollection } from './utils';
const initialState = {
    data: [],
    loading: false,
    count: 0,
};
export const createTakeFlags = createAsyncThunk('takesFlags/create', (flags_1, _a) => __awaiter(void 0, [flags_1, _a], void 0, function* (flags, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.takeFlags.collection.create(flags);
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const deleteTakeFlags = createAsyncThunk('takesFlags/delete', (flags_1, _a) => __awaiter(void 0, [flags_1, _a], void 0, function* (flags, { fulfillWithValue, rejectWithValue }) {
    try {
        const flagsIds = flags.map((flag) => ({ id: flag.id }));
        yield api.takeFlags.collection.delete(flagsIds);
        return fulfillWithValue(flagsIds);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
const { actions, reducer } = createSlice({
    name: 'reduxTakesFlags',
    initialState,
    reducers: {
        setAllTakesFlags: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        // CREATE FLAG RELATION
        builder.addCase(createTakeFlags.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createTakeFlags.fulfilled, (state, { payload }) => {
            const newData = payload.map((relation) => ({
                id: relation.id,
                take: relation.take,
                flag: relation.flag,
            }));
            state.data = [...state.data, ...newData];
        });
        builder.addCase(createTakeFlags.rejected, (state) => {
            state.loading = false;
        });
        // DELETE FLAG RELATION
        builder.addCase(deleteTakeFlags.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteTakeFlags.fulfilled, (state, { payload }) => {
            const idsArr = payload.map((el) => el.id);
            const updatedData = deleteDataFomCollection(state.data, idsArr);
            state.data = updatedData;
        });
        builder.addCase(deleteTakeFlags.rejected, (state) => {
            state.loading = false;
        });
    },
});
export const { setAllTakesFlags } = actions;
export default reducer;
