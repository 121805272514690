import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    data: {},
    loading: false,
    inputFilters: [],
};
const { actions, reducer } = createSlice({
    name: 'tablesFilters',
    initialState,
    reducers: {
        setInputFilters: (state, { payload }) => {
            state.inputFilters = payload;
        },
    },
});
export const { setInputFilters } = actions;
export default reducer;
