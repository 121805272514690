var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import { isNumber } from 'lodash';
import { MUIAutocomplete, Autocomplete, Input, MUISelect, PhoneInput, Rating } from 'app/components/Form';
import Checkbox from 'app/components/Form/Checkbox/Checkbox.jsx';
import AutocompleteMultiple from 'app/components/Form/Autocomplete/AutocompleteMultiple';
import { Duration, durationStr } from 'app/components/Duration/Duration';
import { UploadFilesPreviews } from 'app/components/Medias/UploadFilesModal.jsx';
import { PriorityRead } from 'app/components/Table/Cells/CellPriority/CellPriority.jsx';
import { Richtext } from 'app/components/Form/Richtext/Richtext.jsx';
import TextRead from 'app/components/TextEditor/TextRead.jsx';
import { SmartDateRead, SmartDateInput } from 'app/components/Form/SmartDate/SmartDate.jsx';
import { Timecode } from 'app/components/Timecode/Timecode.jsx';
export const getInput = (property, data, setData) => {
    var _a;
    const { type, key } = property;
    switch (type) {
        case 'number':
        case 'date':
        case 'datetime':
        case 'time':
        case 'text': {
            const props = property.props || {};
            return (_jsx(Input, Object.assign({ value: data[key] || '', type: type, onChange: (e, value) => setData({ [key]: value }), dataCy: (props === null || props === void 0 ? void 0 : props.placeholder) || 'default-input' }, props)));
        }
        case 'richtext': {
            const props = property.props || {};
            return (_jsx(Richtext, Object.assign({ style: { height: '100%' }, value: data[key], onChange: (value) => setData({ [key]: value }) }, props)));
        }
        case 'phone': {
            const props = property.props || {};
            return _jsx(PhoneInput, Object.assign({ value: data[key] || '', onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'rating': {
            const props = property.props || {};
            return _jsx(Rating, Object.assign({ value: data[key] || '', onChange: (value) => setData({ [key]: value }), name: key }, props));
        }
        case 'checkbox': {
            const props = property.props || {};
            return _jsx(Checkbox, Object.assign({ checked: data[key] || false, onChange: (event) => setData({ [key]: !data[key] }) }, props));
        }
        case 'select': {
            const props = property.props || {};
            return _jsx(MUISelect, Object.assign({ value: (_a = data[key]) !== null && _a !== void 0 ? _a : '', onChange: (value) => setData({ [key]: value.value }) }, props));
        }
        case 'muiautocomplete': {
            const props = property.props || {};
            return _jsx(MUIAutocomplete, Object.assign({ value: data[key], onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'autocomplete': {
            const props = property.props || {};
            return _jsx(Autocomplete, Object.assign({ value: data[key], onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'autocompleteMultiple': {
            const props = property.props || {};
            return _jsx(AutocompleteMultiple, Object.assign({ value: data[key], onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'duration': {
            const props = property.props || {};
            return _jsx(Duration, Object.assign({ value: data[key] || 0, onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'smartdate': {
            const props = property.props || {};
            return _jsx(SmartDateInput, Object.assign({ value: data[key], onChange: (value) => setData({ [key]: value }) }, props));
        }
        case 'file': {
            const props = property.props || {};
            return (_jsx(UploadFilesPreviews, Object.assign({ overflow: false, setFiles: (files) => {
                    setData({
                        [property.key]: files,
                    });
                }, files: data[property.key] }, props)));
        }
        case 'timecode': {
            const props = property.props || {};
            return _jsx(Timecode, Object.assign({ value: data[key] || 0, onChange: (value) => setData({ [key]: value }) }, props));
        }
        default:
            throw Error('Invalid editor type');
    }
};
export const viewableValue = (type, value, editorType, props = {}) => {
    switch (type) {
        case 'text': {
            if (editorType && editorType === 'txt') {
                return (_jsx("div", { style: { backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: 5, margin: 10 }, children: _jsx(TextRead, { text: String(value) }) }));
            }
            return value;
        }
        case 'char': {
            if (editorType === 'phone')
                return formatPhoneNumber(value);
            return String(value);
        }
        case 'phone': {
            return formatPhoneNumberIntl(value);
        }
        case 'checkbox':
        case 'bool':
        case 'email':
        case 'float':
        case 'ip':
        case 'url': {
            return String(value);
        }
        case 'number': {
            return String(Math.round(value * 100) / 100);
        }
        case 'time': {
            if (isNumber(value)) {
                if (value / 86400 >= 1)
                    return durationStr(value, 24);
                return moment.utc(moment.duration((value % 86400) * 1000).asMilliseconds()).format('HH:mm');
            }
            return String(value);
        }
        case 'skill':
        case 'select':
        case 'autocomplete':
        case 'autocompleteMultiple': {
            return value.value;
        }
        case 'datetime': {
            if (!value && value !== 0)
                return 'null';
            return new Date(value).toLocaleString();
        }
        case 'date': {
            if (!value && value !== 0)
                return 'null';
            return moment(value).format('DD/MM/YYYY');
        }
        case 'duration': {
            return durationStr(value);
        }
        case 'integer': {
            if (editorType === 'rating') {
                if (!value && value !== 0)
                    return 'null';
                return _jsx(Rating, Object.assign({ value: value, readOnly: true }, props));
            }
            if (editorType === 'priority') {
                if (!value && value !== 0)
                    return 'null';
                return _jsx(PriorityRead, Object.assign({ value: value }, props));
            }
            if (editorType === 'timecode') {
                return _jsx(Timecode, Object.assign({ value: value, readOnly: true, framerate: props.framerate }, props));
            }
            return String(Math.round(value * 100) / 100);
        }
        case 'smartdate': {
            return _jsx(SmartDateRead, Object.assign({ value: value }, props));
        }
        case 'timecode': {
            return _jsx(Timecode, Object.assign({ value: value, readOnly: true }, props));
        }
        default: {
            return console.warn(`Invalid editor type "${type}"`);
        }
    }
};
export const Field = (_a) => {
    var { type, setData, dataKey, data } = _a, props = __rest(_a, ["type", "setData", "dataKey", "data"]);
    return getInput({ type, props, key: dataKey }, data, setData);
};
export const getInputByType = (values) => {
    const { type, params, editorType, key, data, setData, props } = values;
    if (params && params.choice) {
        return getInput({
            type: 'select',
            props: Object.assign({ options: params.choice.map((value) => ({ label: viewableValue(type, value), value })), value: { value: data[key], label: viewableValue(type, data[key]) } }, props),
            key,
        }, data, setData);
    }
    switch (type) {
        case 'bool':
            return getInput({ type: 'checkbox', props, key }, data, setData);
        case 'char':
            return getInput({ type: 'text', props, key }, data, setData);
        case 'date':
            return getInput({ type: 'date', props, key }, data, setData);
        case 'datetime':
            return getInput({ type: 'datetime', props, key }, data, setData);
        case 'duration':
            return getInput({ type: 'duration', props, key }, data, setData);
        case 'email':
            return getInput({ type: 'text', props, key }, data, setData);
        case 'float':
            return getInput({ type: 'number', props: Object.assign({ step: 0.1 }, props), key }, data, setData);
        case 'integer':
            if (editorType && editorType === 'rating') {
                return getInput({ type: 'rating', props, key }, data, setData);
            }
            return getInput({ type: 'number', props: Object.assign({ step: 1 }, props), key }, data, setData);
        case 'ip':
            return getInput({ type: 'text', props, key }, data, setData);
        case 'text':
            if (editorType && editorType === 'txt') {
                return getInput({ type: 'richtext', props, key }, data, setData);
            }
            return getInput({ type: 'text', props, key }, data, setData);
        case 'time':
            return getInput({ type: 'time', props, key }, data, setData);
        case 'url':
            return getInput({ type: 'text', props, key }, data, setData);
        case 'phone':
            return getInput({ type: 'phone', props, key }, data, setData);
        case 'timecode':
            return getInput({ type: 'timecode', props, key }, data, setData);
        default:
            return null;
    }
};
