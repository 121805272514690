import { createSelector } from '@reduxjs/toolkit';
import { takesDataWithFlagsRelations } from './takes';
import { reduxAssetsDataSelector } from './assets';
export const tasksReducer = (state) => state.reduxTasks;
export const tasksSelector = createSelector(tasksReducer, (selector) => selector);
export const tasksDataSelector = createSelector(tasksReducer, (selector) => selector.data);
export const formattedTasksWithTakesSelector = createSelector([tasksDataSelector, takesDataWithFlagsRelations, reduxAssetsDataSelector], (tasks, takes, reduxAssets) => {
    // create relation with takes reducer and assets reducer
    const taskWithRelatedTakes = tasks.map((task) => {
        const relatedTakes = takes.filter((take) => take.task === task.id);
        const relatedAssetInst = reduxAssets.find((asset) => asset.id === task.asset);
        return Object.assign(Object.assign({}, task), { takesInst: relatedTakes, assetInst: relatedAssetInst });
    });
    const formattedData = taskWithRelatedTakes.flatMap((task) => {
        return task.takesInst.flatMap((take) => (Object.assign(Object.assign({}, take), { taskInst: task })));
    });
    return formattedData;
});
