import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from 'app/components/Icons';
import { BurgerButton } from 'app/components/Icons/BurgerButton/BurgerButton.jsx';
import { HorizontalCollapse } from 'app/components/Transitions/HorizontalCollapse.jsx';
import history from 'app/main/routerHistory';
import { MenuContext } from 'app/main/Authenticated/Authenticated';
import classes from './Header.module.scss';
import Nav from './Nav.jsx';
import User from './User.jsx';
import IconsNav from './IconsNav.jsx';
import { SearchingBar } from './SearchingBar/SearchingBar.jsx';
export function Header() {
    const menuContextConsumer = useContext(MenuContext);
    function displayBurgerButton() {
        const location = history.location.pathname.split('/');
        if (location[1] === 'admin')
            return true;
        if (location[1] === 'notifications')
            return true;
        if (location[1] === 'hr')
            return true;
        if (location[1] === 'projects') {
            if (location[2])
                return true;
        }
        return false;
    }
    return (_jsx("div", { className: classes.container, children: _jsxs("header", { className: classes.header, children: [_jsx(HorizontalCollapse, { open: displayBurgerButton(), width: 50, children: _jsx("div", { className: classes.burgerButton, children: _jsx(BurgerButton, { onClick: menuContextConsumer.toggleMenu, open: menuContextConsumer.isOpen }) }) }), _jsxs(Link, { className: classes.name, to: "/", children: [_jsx(Logo, { className: classes.logo }), _jsx("span", { className: classes.nameStart, children: "Over" }), _jsx("span", { className: classes.nameEnd, children: "mind" })] }), _jsx(Nav, {}), _jsx(SearchingBar, {}), _jsx(IconsNav, {}), _jsx(User, {})] }) }));
}
