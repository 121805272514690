var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MUIButton, FormData } from 'app/components/Form';
import { ModalTrigger, ModalConfirmForm } from 'app/components/Modal';
import { messagesTypes, scopesTypes } from './utils';
import { useDispatch } from 'react-redux';
import { createMessage } from 'app/store/reducers/messages';
const MessagesModal = () => {
    const dispatch = useDispatch();
    const messageTypeOptions = [{ label: 'Choose type', value: '', disabled: true }, ...messagesTypes];
    const scopeOptions = [{ label: 'Choose scope', value: '', disabled: true }, ...scopesTypes];
    return (_jsx(ModalTrigger, { modal: _jsx(ModalConfirmForm, { title: "Add message", children: _jsx(FormData, { onSave: (data) => __awaiter(void 0, void 0, void 0, function* () {
                    const message = {
                        title: data.title,
                        text: data.text,
                        messageType: data.messageType,
                        scope: data.scope,
                        startDate: data.startDate,
                        endDate: data.endDate,
                    };
                    const res = yield dispatch(createMessage(message));
                    return res;
                }), flashNotifSuccessLabel: "Message created", defaultData: {
                    startDate: new Date(),
                    endDate: '',
                }, properties: [
                    {
                        key: 'title',
                        label: 'Title (not displayed)',
                        type: 'string',
                        elementProps: { isRequired: true },
                    },
                    {
                        key: 'text',
                        label: 'Text',
                        type: 'richtext',
                        elementProps: { isRequired: true },
                    },
                    {
                        key: 'messageType',
                        label: 'Message Type',
                        type: 'select',
                        elementProps: {
                            fullWidth: true,
                            isRequired: true,
                            options: messageTypeOptions,
                            placeholder: 'Select a message type',
                        },
                    },
                    {
                        key: 'scope',
                        label: 'Scope',
                        type: 'select',
                        elementProps: {
                            fullWidth: true,
                            isRequired: true,
                            options: scopeOptions,
                            placeholder: 'Select a scope',
                        },
                    },
                    {
                        key: 'startDate',
                        label: 'Start Date',
                        type: 'dateTime',
                        elementProps: {
                            isRequired: true,
                        },
                    },
                    {
                        key: 'endDate',
                        label: 'end Date',
                        type: 'dateTime',
                        elementProps: {
                            isRequired: true,
                        },
                    },
                ] }) }), children: _jsx(MUIButton, { icon: "fas-plus", children: "Add Message" }) }));
};
export default MessagesModal;
