/** @flow */
import React, { useState, useEffect, useRef, ReactNode, ReactElement, SyntheticEvent } from 'react'
import cx from 'classnames'
import innerText from 'react-innertext'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem } from 'app/components/MenuItem/MenuItem.jsx'
import type { Option, InputProps } from 'app/core/types'
import type { Option as TSOption } from 'app/core/types/types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import vars from 'app/styles/vars.js'
import { cyLabelFormater } from 'app/libs/helpers/cyTools.js'
import type { TSInputProps } from '../Input/Input.jsx'

import LoaderSmall from '../../LoaderSmall/LoaderSmall.jsx'
import Validators from '../Validators/Validators.jsx'
import classes from './MUISelect.module.scss'

const { colors, mainFont } = vars

export interface TSSelectProps extends TSInputProps {
  autoWidth?: boolean; //	false	If true, the width of the popover will automatically be set according to the items inside the menu, otherwise it will be at least the width of the select input.
  children?: ReactNode; // The option elements to populate the select with. Can be some MenuItem when native is false and option when native is true. ⚠️The MenuItem elements must be direct descendants when native is false.
  defaultValue?: any; // The default element value. Use when the component is not controlled.
  displayEmpty?: boolean; //	false	If true, a value is displayed even if no items are selected. In order to display a meaningful value, a function should be passed to the renderValue prop which returns the value to be displayed when no items are selected. You can only use it when the native prop is false (default).IconComponent	elementType	ArrowDropDownIcon	The icon that displays the arrow.
  id?: string; // The id of the wrapper element or the select element when native.
  input?: ReactElement<any>; // An Input element; does not have to be a material-ui specific Input.
  inputProps?: {}; // Attributes applied to the input element. When native is true, the attributes are applied on the select element.
  label?: ReactElement<any>; // See OutlinedInput#label
  labelId?: string; // The ID of an element that acts as an additional label. The Select will be labelled by the additional label and the selected value.
  labelWidth?: number; // 0	See OutlinedInput#label
  MenuProps?: {}; // Props applied to the Menu element.
  multiple?: boolean; //	false	If true, value must be an array and the menu will support multiple selections.
  native?: boolean; //	false	If true, the component will be using a native select element.
  onClose?: (event: SyntheticEvent<>) => void; // Callback fired when the component requests to be closed. Use in controlled mode (see open).
  onOpen?: (event: SyntheticEvent<>) => void; // Callback fired when the component requests to be opened. Use in controlled mode (see open).
  open?: boolean; // Control select open state. You can only use it when the native prop is false (default).
  renderValue?: (value: any, options: TSSelectProps['options']) => ReactNode; // Render the selected value. You can only use it when the native prop is false (default).
  SelectDisplayProps?: {}; // Props applied to the clickable div element.
  value: any; // The input value. // Providing an empty string will select no options. This prop is required when the native prop is false (default). Set to an empty string '' if you don't want any of the available options to be selected. If the value is an object it must have reference equality with the option in order to be selected. If the value is not an object, the string representation must match with the string representation of the option in order to be selected.
  variant?: 'filled' | 'outlined' | 'standard'; // 'standard'	The variant to use.
  fullWidth?: boolean;

  // Added Props
  hidden?: boolean;
  options: TSOption[];
  onChange: () => void; // 	Callback function fired when a menu item is selected.
  disabledOptions?: string[];
  dataCy?: ReactNode;
  isLoading?: boolean;
  renderLabel?: (option: Option) => ReactNode;

  // Validators
  validatorRef?: () => void;
  validatorStyle?: {};
  errors?: {};
  isRequired?: boolean;
  validators?: {};
}

export type SelectProps = {|
  ...InputProps,
  autoWidth?: boolean, //	false	If true, the width of the popover will automatically be set according to the items inside the menu, otherwise it will be at least the width of the select input.
  children?: React$Node, // The option elements to populate the select with. Can be some MenuItem when native is false and option when native is true. ⚠️The MenuItem elements must be direct descendants when native is false.
  defaultValue?: any, // The default element value. Use when the component is not controlled.
  displayEmpty?: boolean, //	false	If true, a value is displayed even if no items are selected. In order to display a meaningful value, a function should be passed to the renderValue prop which returns the value to be displayed when no items are selected. You can only use it when the native prop is false (default).IconComponent	elementType	ArrowDropDownIcon	The icon that displays the arrow.
  id?: string, // The id of the wrapper element or the select element when native.
  input?: React$Element<any>, // An Input element; does not have to be a material-ui specific Input.
  inputProps?: Object, // Attributes applied to the input element. When native is true, the attributes are applied on the select element.
  label?: React$Element<any>, // See OutlinedInput#label
  labelId?: string, // The ID of an element that acts as an additional label. The Select will be labelled by the additional label and the selected value.
  labelWidth?: number, // 0	See OutlinedInput#label
  MenuProps?: Object, // Props applied to the Menu element.
  multiple?: boolean, //	false	If true, value must be an array and the menu will support multiple selections.
  native?: boolean, //	false	If true, the component will be using a native select element.
  onClose?: (event: SyntheticInputEvent<>) => void, // Callback fired when the component requests to be closed. Use in controlled mode (see open).
  onOpen?: (event: SyntheticInputEvent<>) => void, // Callback fired when the component requests to be opened. Use in controlled mode (see open).
  open?: boolean, // Control select open state. You can only use it when the native prop is false (default).
  renderValue?: (value: any, options: $ElementType<SelectProps, 'options'>) => React$Node, // Render the selected value. You can only use it when the native prop is false (default).
  SelectDisplayProps?: Object, // Props applied to the clickable div element.
  value: any, // The input value. // Providing an empty string will select no options. This prop is required when the native prop is false (default). Set to an empty string '' if you don't want any of the available options to be selected. If the value is an object it must have reference equality with the option in order to be selected. If the value is not an object, the string representation must match with the string representation of the option in order to be selected.
  variant?: 'filled' | 'outlined' | 'standard', // 'standard'	The variant to use.
  fullWidth?: boolean,

  // Added Props
  hidden?: boolean,
  options: Array<Option>,
  onChange: Function, // 	Callback function fired when a menu item is selected.
  disabledOptions?: Array<string>,
  dataCy?: React$Node,
  isLoading?: boolean,
  renderLabel?: (option: Option) => React$Node,

  // Validators
  validatorRef?: Function,
  validatorStyle?: Object,
  errors?: Object,
  isRequired?: boolean,
  validators?: Object,
|}

const useStyles: ({ inputBg?: string }) => { [key: string]: string } = makeStyles(() => ({
  list: {
    padding: 0,
  },
  select: ({ inputBg }) => ({
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      borderRadius: 4,
      backgroundColor: inputBg || undefined,
      boxShadow: '0 0 0 30px rgba(255,255,255,0.4) inset',
    },
  }),
  root: ({ disabled, style }) => ({
    borderRadius: 4,
    border: '1px solid #B3B9c7',
    padding: '4px 22px 5px 5px',
    color: colors.black,
    fontFamily: mainFont,
    boxShadow: disabled ? '0px 100px rgb(0 0 0 / 5%) inset' : undefined,
    ...style,
  }),
  error: {
    borderColor: colors.red,
    boxShadow: `0 0 3px ${colors.red}`,
  },
  input: ({ inputBg }) => ({
    backgroundColor: inputBg || undefined,
    fontWeight: inputBg ? 'bold' : undefined,
    color: inputBg ? getColorFromBackground(inputBg) : undefined,
  }),
  disabled: {
    color: '#888888',
  },
}))

export function MUISelect(props: SelectProps): React$Node {
  const {
    options,
    value,
    onChange,
    disabledOptions,
    validatorRef,
    validatorStyle,
    errors,
    isRequired = false,
    validators,
    multiple,
    hidden,
    dataCy,
    placeholder,
    isLoading,
    disabled,
    renderLabel,
    renderValue: _renderValue,
    style,
    ...rest
  } = props
  const [_options, setOptions] = useState([])
  const [error, setError] = useState(false)

  const componentRef = useRef()

  useEffect(() => {
    let filterdOptions = options
    if (disabledOptions) {
      filterdOptions = filterdOptions.filter((opt) => !disabledOptions.includes(opt.value))
    }
    // $FlowFixMe
    setOptions(filterdOptions)
  }, [options])

  let optionIndex: Array<number> | number
  let option

  if (multiple && Array.isArray(value)) {
    optionIndex = value?.map((val) =>
      _options.findIndex((opt) => opt?.value === (typeof val === 'string' ? val : val?.value)),
    )
  } else {
    optionIndex = _options.findIndex((opt) => opt.value === (value && typeof value === 'object' ? value.value : value))
    option = _options[optionIndex]
  }

  function renderValue(selected: Array<number> | number) {
    if (_renderValue) return _renderValue(selected, _options)

    if (Array.isArray(selected)) {
      if (renderLabel) {
        return <div className="fullWidth flex row wrap">{selected.map((index) => renderLabel(_options[index]))}</div>
      }

      return selected
        .map((index: number) => _options[index]?.label)
        .filter((_) => _)
        .join(', ')
    }
    return _options[selected].label
  }

  function makeOptions(options: Array<Option>) {
    return options.map((option: Option, index: number) => {
      const { label, labelCustom, backgroundColor, icon, value } = option

      let iconMultiple
      if (multiple && Array.isArray(optionIndex)) {
        if (optionIndex.includes(index)) iconMultiple = 'far-check-square'
        else iconMultiple = 'far-square'
      }

      return (
        <MenuItem
          data-testid={cyLabelFormater('option', value || labelCustom || label || index)}
          key={innerText(label)}
          value={index}
          style={{
            backgroundColor,
            color: backgroundColor ? getColorFromBackground(backgroundColor) : undefined,
            fontWeight: backgroundColor ? 'bold' : undefined,
          }}
        >
          {iconMultiple ? <FontIcon icon={iconMultiple} className="marginRight5 grey" /> : null}
          {icon ? <FontIcon icon={icon} className="marginRight5" /> : null}
          {labelCustom || label}
        </MenuItem>
      )
    })
  }

  function _onChange(event: SyntheticInputEvent<>, child?: React$Element<any>) {
    if (onChange) {
      if (multiple) {
        onChange(
          // $FlowFixMe
          _options.filter((opt, index) => event.target.value.includes(index)),
          value,
        )
      } else onChange(_options[Number(event.target.value)])
    }
  }

  const {
    list,
    root,
    disabled: disabledStyle,
    select,
    validationError,
    input,
  } = useStyles({
    inputBg: option?.backgroundColor,
    disabled: isLoading || disabled,
    style,
  })

  return (
    <div className={cx({ [classes.hidden]: hidden }, 'relative fullWidth')}>
      <Select
        autoWidth={true}
        classes={{
          root: cx(root, { [validationError]: error }),
          disabled: disabledStyle,
          select: cx(select, input),
        }}
        style={style}
        multiple={multiple}
        disableUnderline={true}
        placeholder={placeholder}
        MenuProps={{
          autoFocus: true,
          classes: { list },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        IconComponent={
          isLoading
            ? (iconProps) => (
                <div className={classes.loader}>
                  <LoaderSmall />
                </div>
              )
            : undefined
        }
        value={optionIndex !== -1 ? optionIndex : ''}
        {...rest}
        disabled={isLoading || disabled}
        ref={componentRef}
        onChange={_onChange}
        renderValue={multiple ? renderValue : undefined}
        inputProps={{ 'data-testid': cyLabelFormater('select', placeholder) }}
      >
        {makeOptions(_options)}
      </Select>
      <Validators
        ref={(ref) => {
          if (validatorRef) validatorRef(ref)
        }}
        style={{ ...validatorStyle, position: 'absolute', right: 0 }}
        value={
          value && Array.isArray(value)
            ? value.length > 0
              ? value
              : undefined
            : typeof value === 'object'
            ? value?.value
            : value ?? ''
        }
        errors={errors}
        onSuccess={() => setError(false)}
        onError={() => setError(true)}
        validators={{
          required: isRequired,
          ...validators,
        }}
      />
    </div>
  )
}
