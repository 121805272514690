import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useState } from 'react';
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx';
import { Input } from 'app/components/Form';
import FlagsComponent from './FlagsComponent';
import classes from './Flags.module.scss';
export const categoriesWithoutAssets = [
    {
        title: 'Articles',
        category: 'article',
    },
    {
        title: 'Takes',
        category: 'take',
    },
    {
        title: 'Dynamic Approval Values',
        category: 'dynApproval',
    },
];
export const categoriesAssets = [
    {
        title: 'Episodes',
        category: 'ep',
    },
    {
        title: 'Folders',
        category: 'fo',
    },
    {
        title: 'Models',
        category: 'mo',
    },
    {
        title: 'Projects',
        category: 'pj',
    },
    {
        title: 'Shots',
        category: 'sh',
    },
];
export function Flags() {
    const [searchInput, setSearchInput] = useState('');
    return (_jsxs("div", { style: { height: '100%', overflowY: 'auto' }, children: [_jsx(Input, { dataCy: "input-search", placeholder: "Search a flag", value: searchInput, onChange: (e) => setSearchInput(e.target.value), adornmentIcon: "fas-search", styleContainer: { maxWidth: 300, height: 'auto', margin: '15px' } }), searchInput.length === 0 ? (_jsxs("div", { className: classes.flagPanel, children: [_jsx(ExpansionPanel, { dataCy: "assets", title: "Assets", children: _jsx("div", { style: { width: '100%' }, children: categoriesAssets.map((asset) => (_jsx(ExpansionPanel, { dataCy: asset.category, title: `${asset.title}`, children: _jsx(FlagsComponent, { category: asset.category }) }, asset.category))) }) }), categoriesWithoutAssets.map((asset) => (_jsx(ExpansionPanel, { dataCy: asset.category, title: `${asset.title}`, children: _jsx(FlagsComponent, { category: asset.category }) }, asset.category)))] })) : (_jsxs("div", { className: classes.flagPanel, children: [_jsx("h2", { style: { marginLeft: '5px' }, children: "Search Result" }), _jsx(FlagsComponent, { category: "", searchInput: searchInput })] }))] }));
}
