var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { find, map, filter, uniq, uniqBy } from 'lodash';
import Popover from '@material-ui/core/Popover';
import { Badge } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus';
import resources from 'app/store/resources';
import { router as routerAssetDetail } from 'app/containers/Assets/AssetDetail/router';
import { Table } from 'app/components/Table/Table.jsx';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { ModalTrigger, ModalConfirmForm, openModal } from 'app/components/Modal';
import { router as routerTaskDetail } from 'app/containers/Task/router';
import { getResources } from 'app/store/selectors';
import { durationStr } from 'app/components/Duration/Duration';
import { MyHoursCalendar } from 'app/containers/MyHoursCalendar/MyHoursCalendar.jsx';
import { ResizableCol } from 'app/components/ResizableContainer';
import { CellDurationTotal } from './CellDurationTotal/CellDurationTotal.jsx';
import { CellLink, CellAssetsSelect, CellStatus, CellDuration, CellText, CellThumbnail, CellTextDuration, } from 'app/components/Table/Cells';
import { setUserSettings, getUserSettings } from 'app/libs/helpers/userSettings';
import { ToggleButtonGroup, ToggleButton, FormData } from 'app/components/Form';
import { permission } from 'app/containers/Permissions';
import { tableId as myTaskTableId } from 'app/pages/MyTasks/MyTasks.jsx';
import { getLastTakeFromTask } from '../Project/ProductionManagement/FollowUp/utils';
import { Filters } from './Filters';
import classes from './MyHours.module.scss';
import { HowToUserHours } from './HowTo';
import { TableMyHoursCustomActivities } from './TableMyHoursCustomActivities';
import { ModalAddCustomActivity } from './ModalAddCustomActivity';
import { checkTaskPerms, checkMyTasksPerms, checkTakePerms } from '../Project/Tasks/checkPerms';
import { ModalAddAbsenceActivity } from './ModalAddAbsenceActivity';
import TableTimeRecapLeaves from '../HR/TimeRecap/TableTimeReacapLeaves/TableTimeRecapLeaves';
import { fetchAllUserLeaves } from 'app/store/reducers/leaves';
import { colors } from '../../styles/colors';
import { deleteActivity, fetchMyActivities } from 'app/store/reducers/reduxActivities';
export const TableMyHours = (props) => {
    const { tableId, selectedWeek, formatView, history, taskType, match } = props;
    const dispatch = useDispatch();
    const startDate = useMemo(() => moment(selectedWeek).startOf(formatView), [formatView, selectedWeek]);
    const endDate = useMemo(() => moment(selectedWeek).endOf(formatView), [formatView, selectedWeek]);
    const startDateString = useMemo(() => startDate.format('YYYY-MM-DD'), [startDate]);
    const endDateString = useMemo(() => endDate.format('YYYY-MM-DD'), [endDate]);
    const allotmentTasks = taskType === 'alt';
    const { data: userLeaves } = useSelector((state) => state.leaves);
    const user = useSelector((state) => state.user);
    const { loggedUserActivities: { data: activities }, } = useSelector((state) => state.reduxActivities);
    const [pastTasks, togglePastTasks] = useState(false);
    const [toggleCustomActivities, setToggleCustomActivities] = useState(getUserSettings('MyHours-customActivity') === 'true');
    const [progressionStatus, setProgressionStatus] = useState();
    const [projects, setProjects] = useState([]);
    const [steps, setSteps] = useState();
    const myActivitiesInstanceRef = useRef();
    const myCustomActivitiesInstanceRef = useRef();
    const isMount = useRef(true);
    const getLeaveDayPart = (date, leave) => {
        if (!leave)
            return null;
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const formattedLeaveStartDate = moment(leave === null || leave === void 0 ? void 0 : leave.startDate).format('YYYY-MM-DD');
        const leaveStartHour = moment.utc(leave === null || leave === void 0 ? void 0 : leave.startDate).format('HH');
        const formattedLeaveEndDate = moment(leave === null || leave === void 0 ? void 0 : leave.endDate).format('YYYY-MM-DD');
        const leaveEndHour = moment.utc(leave === null || leave === void 0 ? void 0 : leave.endDate).format('HH');
        if (formattedDate === formattedLeaveEndDate && Number(leaveEndHour) < 18)
            return 'morning';
        if (formattedDate === formattedLeaveStartDate && Number(leaveStartHour) > 8)
            return 'afternoon';
        return 'fullday';
    };
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (user === null || user === void 0 ? void 0 : user.asset) {
                dispatch(fetchAllUserLeaves());
            }
        }))();
    }, [user]);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (user === null || user === void 0 ? void 0 : user.asset) {
                dispatch(fetchMyActivities({ date__range: `${startDateString},${endDateString}`, page: 1, page_size: 1000 }));
            }
        }))();
    }, [startDate, endDate, user]);
    useEffect(() => {
        Promise.all([
            resources.progressionStatus.fetchAll({ params: { queries: { page_size: 1000 } } }),
            resources.steps.fetchAll({ params: { queries: { page_size: 1000 } } }),
        ]).then(() => {
            if (!isMount.current)
                return;
            setProgressionStatus(getResources(undefined, 'progressionStatus'));
            setProjects(map(user.projects));
            setSteps(getResources(undefined, 'steps'));
        });
        return () => {
            isMount.current = false;
        };
    }, []);
    const userTaskActivities = useSelector((state) => filter(getResources(state, 'activities', (activity) => Boolean(activity.user === user.asset &&
        activity.date >= startDateString &&
        activity.date <= endDateString &&
        activity.task), ['taskInst']))) || [];
    function getActivitiesSumByDate(date) {
        return uniqBy([...userTaskActivities, ...activities], 'id').filter((activity) => {
            var _a, _b, _c, _d;
            if (taskType && ((_b = (_a = activity === null || activity === void 0 ? void 0 : activity.taskInst) === null || _a === void 0 ? void 0 : _a.taskTypeInst) === null || _b === void 0 ? void 0 : _b.name) && ((_d = (_c = activity === null || activity === void 0 ? void 0 : activity.taskInst) === null || _c === void 0 ? void 0 : _c.taskTypeInst) === null || _d === void 0 ? void 0 : _d.name) !== taskType)
                return false;
            return activity.date === date && user.asset === activity.user;
        });
    }
    function findActivity(activitiesInst, date) {
        return find(activitiesInst, (activity) => {
            return activity.date === date && user.asset === activity.user && !activity.activityType;
        });
    }
    function defaultActions(instance, cell) {
        return [
            { separator: true },
            {
                label: 'Show task details',
                onClick: (e) => routerTaskDetail.goTo('index', { taskId: cell.row.original.id }, { rightPanel: true }),
                editAction: false,
            },
        ];
    }
    function addTimeOnSelection(instance, cell) {
        return {
            label: 'Add time on selection',
            editAction: true,
            onClick: (e) => {
                var _a;
                const { save } = ((_a = cell.getCellProps()) === null || _a === void 0 ? void 0 : _a.edition) || {};
                return openModal(_jsx(ModalConfirmForm, { title: "Add time on selected tasks", children: _jsx(FormData, { onSave: ({ duration }) => {
                            const { selectedCells } = instance.getLastestSelection();
                            const value = Math.round(duration / Object.keys(selectedCells || {}).length);
                            return save({ duration: value });
                        }, properties: [
                            {
                                label: 'Duration',
                                key: 'duration',
                                type: 'duration',
                                elementProps: {
                                    isRequired: true,
                                    hide: { days: true },
                                },
                            },
                        ] }) }));
            },
        };
    }
    function addCustomActivity(instance, cell) {
        const date = moment(cell.id, 'YYYY-MM-DD');
        return {
            label: 'Add an other activity',
            editAction: true,
            onClick: (e) => {
                return openModal(_jsx(ModalAddCustomActivity, { useRedux: true, userId: user.asset, preSelectedDate: date.toDate() }));
            },
        };
    }
    function addLeave(instance, cell) {
        const { selectedCells } = instance.getLastestSelection();
        const date = moment(cell.id, 'YYYY-MM-DD');
        function openLeaveModal(duration, dayPart = '', period = false) {
            return openModal(_jsx(ModalAddAbsenceActivity, { userId: user.asset, dayPart: dayPart, tableTime: true, period: period, preSelectedDate: selectedCells && Object.keys(selectedCells).length === 1 ? date.toDate() : undefined, duration: duration, preSelectedCells: selectedCells, onChange: (activities) => {
                    if (!myCustomActivitiesInstanceRef.current)
                        return;
                    const { setStateData, data } = myCustomActivitiesInstanceRef.current;
                    const newData = [...activities, ...data];
                    setStateData(newData.reduce((acc, activity) => {
                        acc[activity.id] = activity;
                        return acc;
                    }, {}));
                } }));
        }
        return {
            label: 'Add a leave',
            editAction: true,
            items: selectedCells && Object.keys(selectedCells).length < 2
                ? [
                    {
                        label: 'Morning',
                        editAction: true,
                        onClick: () => openLeaveModal(60 * 60 * 4, 'morning'),
                    },
                    {
                        label: 'Afternoon',
                        editAction: true,
                        onClick: () => openLeaveModal(60 * 60 * 4, 'afternoon'),
                    },
                    {
                        label: 'Full day',
                        editAction: true,
                        onClick: () => openLeaveModal(60 * 60 * 8, ''),
                    },
                ]
                : [
                    {
                        label: 'Period',
                        editAction: true,
                        onClick: () => openLeaveModal(60 * 60 * 8, '', true),
                    },
                ],
        };
    }
    function onChangeSelectWeek(type, week) {
        const url = match.url.split('/')[1];
        history.pushWithQuery({}, `/${url}/${type}/${week.format(type === 'month' ? 'MM-YYYY' : 'w-YYYY')}`);
    }
    function getExtendToolbar(instance) {
        const nbCustmActivities = userTaskActivities.reduce((acc, activity) => {
            return activity.user === user.asset &&
                activity.activityType &&
                moment(activity.date).isSame(selectedWeek, formatView)
                ? acc + 1
                : acc;
        }, 0);
        return (_jsxs(_Fragment, { children: [_jsx(ModalTrigger, { modal: _jsx(HowToUserHours, {}), children: _jsx(FontIcon, { icon: "fas-info-circle", className: classes.infoButton }) }), _jsxs(ToggleButtonGroup, { className: "marginRight10", children: [_jsx(ToggleButton, { selected: formatView === 'week', disabled: formatView === 'week', onClick: () => onChangeSelectWeek('week', selectedWeek), children: "Week" }), _jsx(ToggleButton, { selected: formatView === 'month', disabled: formatView === 'month', onClick: () => onChangeSelectWeek('month', selectedWeek), children: "Month" })] }), allotmentTasks ? null : (_jsxs(ToggleButtonGroup, { style: { marginRight: 10 }, children: [_jsx(ToggleButton, { icon: "far-square", onClick: () => togglePastTasks(false), selected: !pastTasks, children: "Current" }), _jsx(ToggleButton, { icon: "fas-check-square", onClick: () => togglePastTasks(true), selected: pastTasks, children: "Past" })] })), _jsx(PopupState, { variant: "popover", popupId: "calendar", children: (popupState) => {
                        const _props = bindTrigger(popupState);
                        return (_jsxs(_Fragment, { children: [_jsxs(ToggleButtonGroup, { className: "marginRight10", children: [_jsx(ToggleButton, { onClick: () => {
                                                const previous = moment(selectedWeek).subtract(1, formatView);
                                                onChangeSelectWeek(formatView, previous);
                                            }, children: _jsx(FontIcon, { icon: "fas-chevron-left" }) }), _jsx(ToggleButton, Object.assign({ icon: "fas-calendar" }, _props, { children: formatView === 'week' ? (_jsxs(_Fragment, { children: [startDate.format('DD/MM/YYYY'), " - ", endDate.format('DD/MM/YYYY')] })) : (_jsx("span", { className: "capitalize", children: startDate.format('MMMM YYYY') })) })), _jsx(ToggleButton, { onClick: () => {
                                                const next = moment(selectedWeek).add(1, formatView);
                                                onChangeSelectWeek(formatView, next);
                                            }, children: _jsx(FontIcon, { icon: "fas-chevron-right" }) })] }), _jsx(Popover, Object.assign({}, bindPopover(popupState), { anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }, transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }, children: _jsx(MyHoursCalendar, { display: formatView, selectedWeek: selectedWeek, userId: user.asset, onSelectWeek: (startWeek) => onChangeSelectWeek(formatView, startWeek), onSelectMonth: (month) => onChangeSelectWeek(formatView, month) }) }))] }));
                    } }), _jsx(Badge, { overlap: "circular", badgeContent: nbCustmActivities, color: "primary", invisible: toggleCustomActivities || nbCustmActivities === 0, children: _jsx(ToggleButton, { selected: toggleCustomActivities, style: { marginLeft: 10 }, onClick: () => {
                            setUserSettings('MyHours-customActivity', String(!toggleCustomActivities));
                            setToggleCustomActivities(!toggleCustomActivities);
                        }, children: "Other activities" }) })] }));
    }
    const columns = useMemo(() => {
        const accumulator = moment(startDate);
        const selectedDays = [];
        while (accumulator.toDate() < endDate.toDate()) {
            selectedDays.push(moment(accumulator));
            accumulator.add(1, 'day');
        }
        return [
            {
                Header: 'Task',
                id: 'tasks',
                columns: [
                    CellLink({
                        Header: 'Asset',
                        id: 'asset',
                        accessor: 'assetInst.name',
                        fixed: 'left',
                        fixable: false,
                        hiddenable: true,
                        readOnly: true,
                        showRemoved: true,
                        actions: defaultActions,
                        sortingKey: 'asset__name',
                        onClick: (row) => routerAssetDetail.goTo('index', { assetId: row.original.assetInst.id }, { rightPanel: true }),
                    }),
                    CellText({
                        Header: 'Step',
                        id: 'step',
                        fixed: 'left',
                        hiddenable: true,
                        fixable: true,
                        readOnly: true,
                        actions: defaultActions,
                        accessor: 'stepInst.name',
                        sortingKey: 'step__name',
                        width: 100,
                    }),
                    CellThumbnail({
                        Header: 'Thumbnail',
                        accessor: 'assetInst.thumbnailInst',
                        id: 'thumbnail',
                        fixed: 'left',
                        fixable: true,
                        hiddenable: true,
                        width: 90,
                        readOnly: false,
                        actions: (instance, cell) => {
                            var _a, _b;
                            return checkTaskPerms(['edit', 'delete', ...defaultActions(instance, cell)], (_b = (_a = cell.row.original) === null || _a === void 0 ? void 0 : _a.assetInst) === null || _b === void 0 ? void 0 : _b.project);
                        },
                        save: {
                            resource: 'assets',
                            formatData: (item, value, cell, instance, type) => {
                                if (type === 'delete') {
                                    return {
                                        id: item.taskInst && item.taskInst.assetInst && item.taskInst.assetInst.id,
                                        thumbnail: null,
                                    };
                                }
                                return {
                                    id: item.taskInst && item.taskInst.assetInst && item.taskInst.assetInst.id,
                                    thumbnail: typeof value === 'string' ? value : value === null || value === void 0 ? void 0 : value.id,
                                };
                            },
                        },
                    }),
                    CellLink({
                        Header: 'Project',
                        accessor: (task) => {
                            if (!task || !task.assetInst)
                                return '';
                            const project = getResources(undefined, 'assets', task.assetInst.project);
                            return project && project.name;
                        },
                        hiddenable: true,
                        fixable: true,
                        fixed: 'left',
                        readOnly: true,
                        actions: defaultActions,
                        id: 'project',
                        onClick: (row) => history.push(`/projects/${row.original.assetInst.project}`),
                        sortingKey: 'asset__project__name',
                    }),
                    CellText({
                        Header: 'Start date',
                        id: 'startDate',
                        fixed: 'left',
                        hiddenable: true,
                        fixable: true,
                        inputProps: {
                            type: 'date',
                        },
                        readOnly: true,
                        actions: defaultActions,
                        accessor: (task) => moment(task.startDate).format('YYYY-MM-DD'),
                        width: 100,
                    }),
                    CellText({
                        Header: 'End date',
                        id: 'endDate',
                        fixed: 'left',
                        hiddenable: true,
                        fixable: true,
                        inputProps: {
                            type: 'date',
                        },
                        readOnly: true,
                        actions: defaultActions,
                        accessor: (task) => moment(task.endDate).format('YYYY-MM-DD'),
                        width: 100,
                    }),
                    CellAssetsSelect({
                        Header: 'Asset parent',
                        fixable: true,
                        placeholder: '',
                        hiddenable: true,
                        fixed: 'left',
                        readOnly: true,
                        showIcon: true,
                        id: 'asset__parent__uuid',
                        width: 170,
                        actions: defaultActions,
                        accessor: 'assetParentInst',
                        sortingKey: 'asset__parent__name',
                    }),
                    CellStatus({
                        Header: 'Status',
                        id: 'status',
                        fixable: true,
                        fixed: 'left',
                        hiddenable: true,
                        readOnly: false,
                        sortingKey: 'status__name',
                        accessor: 'status',
                        isHashed: (cell) => {
                            const lastTake = getLastTakeFromTask(cell.row.original);
                            if (lastTake) {
                                const { number, statusInst = {} } = lastTake;
                                return number === 1 && statusInst.statusType === 0;
                            }
                            return false;
                        },
                        progressionStatus: progressionStatus || {},
                        width: 160,
                        componentProps: {
                            disabledOptions: !permission(['my tasks___can close a take'])
                                ? progressionStatusFilter(progressionStatus || {}, 'with', ['new', 'close', 'retake']).map((ps) => ps.id)
                                : [],
                        },
                        actions: (instance, cell) => {
                            var _a, _b;
                            return uniq(checkMyTasksPerms(cell.row, ['edit', 'copy', 'past'], progressionStatus || {}, myTaskTableId, 'status', (_b = (_a = cell.row.original) === null || _a === void 0 ? void 0 : _a.assetInst) === null || _b === void 0 ? void 0 : _b.project)
                                .concat(checkTakePerms(['edit', 'copy', 'past']))
                                .concat(defaultActions(instance, cell)));
                        },
                        Total: () => _jsx("div", { className: "flex center bold", children: "Total per day" }),
                        save: {
                            resource: 'takes',
                            formatData: (item, value) => {
                                const lastTake = getLastTakeFromTask(item);
                                if (!lastTake)
                                    return null;
                                return { id: lastTake.id, status: value, task: item.id };
                            },
                        },
                    }),
                ],
            },
            {
                Header: 'Activities',
                id: 'activities',
                resizable: false,
                columns: selectedDays.map((date, index) => {
                    const isFutureDay = new Date() < date.toDate();
                    const isWeekend = [0, 6].includes(date.day());
                    const isCurrentDay = moment().isSame(date, 'day');
                    const dateString = date.format('YYYY-MM-DD');
                    return CellTextDuration({
                        Header: '',
                        headerSubLabel: (_jsxs("div", { children: [_jsx("div", { className: "fontSize12 normal", children: date.format('ddd') }), _jsx("p", { children: date.format('D') })] })),
                        id: date.format('YYYY-MM-DD'),
                        readOnly: false,
                        resizable: false,
                        draggable: false,
                        noFilterOption: true,
                        headerStyle: {
                            fontWeight: isCurrentDay ? 'bold' : undefined,
                            color: isCurrentDay ? '#ffffff' : undefined,
                            backgroundColor: isCurrentDay ? 'rgba(0,138,230)' : isWeekend ? 'rgba(0,0,0,0.03)' : undefined,
                        },
                        cellsStyle: {
                            backgroundColor: isWeekend || isFutureDay ? 'rgba(0,0,0,0.04)' : undefined,
                        },
                        accessor: (item) => {
                            if (!item)
                                return null;
                            const activity = findActivity(item.activitiesInst, dateString);
                            return activity || null;
                        },
                        durationProps: {
                            hide: { days: true },
                        },
                        textAlignment: index < 2
                            ? 'left'
                            : index === selectedDays.length - 1 || index === selectedDays.length - 2
                                ? 'right'
                                : 'center',
                        actions: (instance, cell) => !isFutureDay
                            ? [
                                'edit',
                                'delete',
                                'copy',
                                'past',
                                'separator',
                                addTimeOnSelection(instance, cell),
                                addCustomActivity(instance, cell),
                                addLeave(instance, cell),
                                ...defaultActions(instance, cell),
                            ]
                            : ['delete', 'separator', ...defaultActions(instance, cell), 'separator', addLeave(instance, cell)],
                        Total: (instance) => {
                            const activitiesByDate = getActivitiesSumByDate(dateString);
                            const dayLeave = userLeaves.find(({ startDate, endDate }) => {
                                if (!startDate && !endDate)
                                    return;
                                const sd = moment(startDate).format('MM/DD/YYYY');
                                const ed = moment(endDate).format('MM/DD/YYYY');
                                return moment(dateString).isBetween(sd, ed, 'day', '[]');
                            });
                            let leaveType = getLeaveDayPart(dateString, dayLeave);
                            const leaveDuration = !leaveType ? 0 : leaveType === 'fullday' ? 28800 : 14400;
                            return (_jsxs(_Fragment, { children: [_jsx("div", { style: { height: `calc(100% - ${leaveType ? 3 : 0}px)`, width: '100%' }, children: _jsx(CellDurationTotal, { duration: activitiesByDate.reduce((acc, activity) => acc + activity.duration, 0) + leaveDuration, dateISO: date.toISOString() }) }), leaveType ? (_jsx("div", { style: {
                                            height: '3px',
                                            width: leaveType === 'fullday' ? '100%' : '50%',
                                            marginLeft: leaveType === 'afternoon' ? '50%' : undefined,
                                            backgroundColor: colors.red,
                                        } })) : null] }));
                        },
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                const activity = findActivity(item.activitiesInst, dateString);
                                const { duration, text } = value || { duration: 0, text: '' };
                                if (type === 'delete' || duration < 1) {
                                    dispatch(deleteActivity({ activitiesIds: [activity.id], isFromMyHours: true }));
                                    return activity ? activity.id : null;
                                }
                                if (!activity) {
                                    return {
                                        duration,
                                        task: item.id,
                                        date: dateString,
                                        user: user.asset,
                                        comment: text,
                                    };
                                }
                                return {
                                    id: activity.id,
                                    duration,
                                    comment: text,
                                };
                            },
                        },
                        width: formatView === 'month' ? 60 : 120,
                    });
                }),
            },
            {
                Header: '',
                id: 'totalPerTaskGrp',
                columns: [
                    CellDuration({
                        Header: 'Total per task',
                        id: 'totalDurationForWeek',
                        fixed: 'right',
                        readOnly: true,
                        accessor: (item) => {
                            const duration = filter(item.activitiesInst, (activity) => {
                                return (activity.date >= startDateString &&
                                    activity.date <= endDateString &&
                                    user.asset === activity.user &&
                                    !activity.activityType);
                            }).reduce((acc, activity) => activity.duration + acc, 0);
                            return duration;
                        },
                        durationProps: {
                            hide: { days: true },
                        },
                        actions: (instance, cell) => defaultActions(instance, cell),
                        Total: (footer) => {
                            const activitiesDuration = uniqBy([...userTaskActivities, ...activities], 'id')
                                .filter((activity) => {
                                const dateActivity = new Date(activity.date);
                                return (dateActivity >= startDate.toDate() &&
                                    dateActivity <= endDate.toDate() &&
                                    user.asset === activity.user);
                            })
                                .reduce((acc, activity) => activity.duration + acc, 0);
                            const duration = durationStr(activitiesDuration, 24, { days: true });
                            return _jsx("div", { className: "flex center alignCenter bold fullHeight", children: duration });
                        },
                    }),
                ],
            },
        ];
    }, [startDate, endDate, progressionStatus, formatView, userTaskActivities]);
    const resourcesParams = useMemo(() => ({
        resourceType: 'tasks',
        requestName: 'fetchMyHours',
        requestData: {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            pastTasks,
            altTaskType: allotmentTasks,
        },
        includedResources: [
            'activitiesInst',
            'statusInst',
            'assetInst',
            'assetInst.thumbnailInst',
            'stepInst',
            'takesInst',
            'takesInst.statusInst',
        ],
        queries: {
            status__statusType__in: allotmentTasks ? undefined : pastTasks ? '3' : '0,2,4',
        },
    }), [startDate, endDate, pastTasks, allotmentTasks]);
    const filters = useMemo(() => {
        if (steps)
            return new Filters({ status: progressionStatus || {}, steps }).getFilters();
        return undefined;
    }, [progressionStatus, steps]);
    if (!steps || !progressionStatus)
        return null;
    return (_jsx("div", { className: "flex fullWidth fullHeight", "data-testid": "page-table-my-hours", children: _jsx(ResizableCol, { defaultLeftSize: formatView === 'month' ? 3 : 2, optionName: "UserHoursVertResizing", toggleRight: toggleCustomActivities, disabled: undefined, left: _jsx(Table, { tableId: tableId, tableViewKey: `${startDate.toISOString()}-${endDate.toISOString()}-${String(pastTasks)}-${String(toggleCustomActivities)}`, filters: filters, resourcesParams: resourcesParams, columns: columns, extendToolbar: getExtendToolbar, getInstance: (instance) => {
                    myActivitiesInstanceRef.current = instance;
                }, lockColumnOrder: true }), right: _jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }, children: [_jsx(TableMyHoursCustomActivities, { user: user, projects: projects, getInstance: (instance) => {
                            myCustomActivitiesInstanceRef.current = instance;
                        }, formatView: formatView, selectedWeek: selectedWeek }), _jsx(TableTimeRecapLeaves, { userId: user.asset, formatView: formatView, selectedWeek: selectedWeek })] }) }) }));
};
