var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import { TableTasksRedux } from 'app/pages/Project/Tasks/TableTasksRedux';
import getProjectIdFromURL from '../../../core/utils/getProjectIdFromURL';
import { useDispatch, useSelector } from 'react-redux';
import { map, pickBy } from 'lodash';
import { fetchAllTasks, fetchAllTasksStats } from 'app/store/reducers/tasks';
import { userPrefLocalResource } from '../../../core/utils/localUserPrefs.js';
import { tableUserPrefsDataSelector, tableUserPrefsSortBySelector } from 'app/store/selectors/tableUserPrefs';
import { formattedTasksWithTakesSelector, tasksSelector } from 'app/store/selectors/tasks';
import useWebsocket from 'app/core/api/useWebSocket';
import { setLoader } from 'app/store/reducers/tablesPrefsLoader';
import { tableFiltersDataSelector } from 'app/store/selectors/tablesFilters';
import { urlToFilters, filtersToQueries } from 'app/components/Table/hooks/useFiltersUtils.js';
import { useLocation } from 'react-router-dom';
import { useProgress } from '../../../hooks/useProgress';
import { getAssetsAttributValues } from 'app/store/selectors/getAssetsAttributValues';
import { getResources } from 'app/store/selectors';
import { getSubResources } from 'app/store/resources/utils/getSubResources';
import { Filters as DefaultFilters } from './Filters.js';
import { getURLParamsKey } from 'app/components/Table/utils.js';
import { setInputFilters } from 'app/store/reducers/tablesFilters';
import { filtersToURL } from 'app/components/Table/hooks/useFiltersUtils.js';
import allRequests from './tasksRequests';
import routerHistory from 'app/main/routerHistory.js';
const Tasks = () => {
    var _a, _b;
    useWebsocket();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { initProgresses, updateProgress, progress } = useProgress();
    const tableId = 'plannerTaskTable';
    const table = (_a = userPrefLocalResource.getData('tables')) === null || _a === void 0 ? void 0 : _a[tableId];
    const projectId = getProjectIdFromURL();
    const [statsOpen, setStatsOpen] = useState(false);
    const [requestsLoading, setRequestsLoading] = useState(true);
    const [filtersloading, setFiltersLoading] = useState(true);
    const tableUserPrefs = useSelector(tableUserPrefsDataSelector(tableId));
    const sortBy = useSelector(tableUserPrefsSortBySelector(tableId));
    const { count, loading, stats, statsIsLoading } = useSelector(tasksSelector);
    const isLoadingSorting = useSelector((state) => state.tablesPrefsLoader.loading);
    const filtersData = useSelector(tableFiltersDataSelector);
    const tasksData = useSelector(formattedTasksWithTakesSelector);
    const categories = ((_b = getAssetsAttributValues(undefined, 'category')) === null || _b === void 0 ? void 0 : _b.values) || [];
    const projects = getResources(undefined, 'assets', { assetType: 'pj' });
    const episodes = useSelector((state) => pickBy(state.assets.resources, (asset) => asset.assetType === 'ep' && asset.project === getProjectIdFromURL()));
    const progressionStatus = useSelector((state) => state.progressionStatus.resources);
    const assetsAttributes = map(getResources(undefined, 'attributes', projectId ? { project: projectId } : undefined));
    const steps = () => {
        const stepProjects = getResources(undefined, 'stepProjects', projectId ? { project: projectId } : undefined, [
            'stepInst',
        ]);
        if (projectId)
            return getSubResources(stepProjects, 'stepInst');
        return getResources(undefined, 'steps');
    };
    const getOrderingKeys = ({ sortingKeys }) => {
        const sortingsStrings = map(sortingKeys, (sort) => {
            return sort.key;
        });
        return sortingsStrings.join();
    };
    const filters = useMemo(() => {
        if (!requestsLoading) {
            const filt = new DefaultFilters({
                episodes,
                categories,
                progressionStatus,
                assetsAttributes,
                projects,
            }).getFilters();
            return filt;
        }
    }, [requestsLoading]);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield allRequests({ projectId, initProgresses, updateProgress });
            setRequestsLoading(false);
        }))();
    }, []);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!search && filtersloading) {
                setFiltersLoading(false);
            }
            if (search && filters.length && filtersloading) {
                const params = new URLSearchParams(search);
                const urlCurrentFilters = params.get(getURLParamsKey('filters', tableId));
                const urlFilters = yield urlToFilters(urlCurrentFilters, filters, undefined, undefined, false);
                dispatch(setInputFilters(urlFilters));
                setFiltersLoading(false);
            }
        }))();
    }, [filters]);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!isLoadingSorting && !requestsLoading && !filtersloading) {
                const { queries: filtQueries } = filtersToQueries(filtersData);
                const url = filtersToURL(filtersData);
                routerHistory.updateQuery({ [getURLParamsKey('filters', tableId)]: url || null });
                const queries = Object.assign({ page_size: (tableUserPrefs === null || tableUserPrefs === void 0 ? void 0 : tableUserPrefs.pageSize) || (table === null || table === void 0 ? void 0 : table.pageSize) || 50, page: (tableUserPrefs === null || tableUserPrefs === void 0 ? void 0 : tableUserPrefs.page) || 1, ordering: getOrderingKeys(sortBy || {}) || '' }, filtQueries);
                dispatch(fetchAllTasks(queries));
            }
        }))();
    }, [
        tableUserPrefs === null || tableUserPrefs === void 0 ? void 0 : tableUserPrefs.pageSize,
        tableUserPrefs === null || tableUserPrefs === void 0 ? void 0 : tableUserPrefs.page,
        isLoadingSorting,
        sortBy,
        requestsLoading,
        filtersloading,
        filtersData,
    ]);
    useEffect(() => {
        return () => {
            dispatch(setLoader(true));
        };
    }, []);
    useEffect(() => {
        if (statsOpen) {
            dispatch(fetchAllTasksStats());
        }
    }, [statsOpen]);
    return (_jsx("div", { className: "fullWidth fullHeight", children: _jsx(TableTasksRedux, { tableId: tableId, enableStats: true, projectId: projectId, data: tasksData, loading: loading, count: count, statsOpen: statsOpen, setStatsOpen: setStatsOpen, stats: stats, statsIsLoading: statsIsLoading, requestsLoading: requestsLoading, assetsAttributes: assetsAttributes, progressionStatus: progressionStatus, steps: steps, projects: projects, filters: filters }) }));
};
export default Tasks;
