/** @flow */
import dropRight from 'lodash/dropRight'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import type { CellInstance } from '../types'

const DND_ITEM_TYPE = 'row'

type Props = {|
  instance: CellInstance,
  row: Object,
  dropRef: React$ElementRef<any>,
  dragRef: React$ElementRef<any>,
  disabled?: boolean,
|}

export const useRowDrop = (props: Props): { isDragging: boolean, isOver: boolean, canDrop: boolean } => {
  const { row, dropRef, dragRef, disabled, instance } = props
  const { swapRowsPosition } = instance

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!monitor.canDrop() || disabled) return
      const dragRowId = item.rowId
      const hoverRowId = row.id
      if (dragRowId === hoverRowId) return
      swapRowsPosition?.(dragRowId, hoverRowId)
      item.rowId = hoverRowId
    },
    canDrop(item, monitor) {
      if (disabled) return false
      const { rowId } = item
      const itemDepth = dropRight(rowId.split('.')).toString()
      const rowDepth = dropRight(row.id.split('.')).toString()
      return itemDepth === rowDepth
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { rowId: row.id, initialRowId: row.id },
    end(item) {
      if (disabled) return
      const { updateRowRank } = row.getRowProps()
      const { initialRowId, rowId } = item
      updateRowRank(rowId, initialRowId)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  if (disabled || !swapRowsPosition) {
    return {
      isDragging: false,
      isOver: false,
      canDrop: false,
    }
  }

  preview(getEmptyImage(), { captureDraggingState: true })
  drop(dropRef)
  drag(dragRef)

  return {
    isDragging,
    isOver,
    canDrop,
  }
}
