import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import store from 'app/store/index';
import { ModalInstances } from 'app/components/Modal';
import { MouseTooltip } from 'app/components/MouseTooltip/MouseTooltip';
import Notif from 'app/containers/Notif/Notif';
import { OverPage } from 'app/components/OverPage/OverPage.jsx';
import CheckUpdates from 'app/containers/CheckUpdates/CheckUpdates.jsx';
import Error from 'app/components/Error/Error.jsx';
import { HowTo } from 'app/components/HowTo/HowTo.jsx';
import theme from 'app/styles/createTheme.js';
import { NotificationsHOC } from 'app/components/Notifications/NotificationsHOC.jsx';
import { PictureVisualization } from 'app/components/PictureVisualization/PictureVisualization';
import { DocumentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import history from './routerHistory';
import MainRoutes from './MainRoutes';
import classes from './Main.module.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
export default function () {
    useEffect(() => {
        const $HTML = document.querySelector('html');
        if (window.gitlab) {
            Object.entries(window.gitlab).forEach(([key, value]) => {
                $HTML === null || $HTML === void 0 ? void 0 : $HTML.setAttribute(`data-${key}`, String(value));
            });
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}), _jsx(MuiThemeProvider, { theme: theme, children: _jsx(Router, { history: history, children: _jsx(Error, { children: _jsx(DndProvider, { backend: HTML5Backend, children: _jsx(PersistGate, { loading: null, persistor: persistStore(store), children: _jsxs(Provider, { store: store, children: [_jsx(DocumentTitle, {}), _jsx("div", { className: classes.mainLayout, children: _jsxs(NotificationsHOC, { children: [_jsx(CheckUpdates, {}), _jsx(Notif, {}), _jsx(HowTo, { history: history }), _jsx(PictureVisualization, {}), _jsx(MainRoutes, {}), _jsx(OverPage, {}), _jsx(ModalInstances, {}), _jsx(MouseTooltip, {})] }) })] }) }) }) }) }) })] }));
}
