/** @flow */
import React, { useEffect } from 'react'
import moment from 'moment'
import type { Asset, ID, Pipe, Activity } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { MUIModal } from 'app/components/Modal'
import { TableTasks } from 'app/pages/Project/Tasks/TableTasks.tsx'
import { getResources } from 'app/store/selectors'
import resources from '../../../store/resources'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserTasks } from '../../../store/reducers/tasks'
import { userPrefLocalResource } from '../../../core/utils/localUserPrefs'
import { tableUserPrefsDataSelector } from '../../../store/selectors/tableUserPrefs'
import { formattedTasksWithTakesSelector, tasksSelector } from '../../../store/selectors/tasks'

type Props = {|
  user: Asset,
  date: Date,
  onAddActivities?: (activities: Array<Activity>) => void,
|}

function ModalAddActivitiesForUserView(props: Props): React$Node {
  const { date, user, onAddActivities, ...rest } = props
  const dispatch = useDispatch()
  const tableId = 'AddActivitiesTableTasks'
  const table = userPrefLocalResource.getData('tables')?.[tableId]
  const { count, loading } = useSelector(tasksSelector)
  const data = useSelector(formattedTasksWithTakesSelector)
  const tableUserPrefs = useSelector(tableUserPrefsDataSelector(tableId))

  const queries = {
    page_size: tableUserPrefs?.pageSize || table?.pageSize || 50,
    page: tableUserPrefs?.page || 1,
  }

  useEffect(() => {
    dispatch(fetchUserTasks({ userId: user.id, queries }))
  }, [tableUserPrefs?.pageSize, tableUserPrefs?.page])

  return (
    <MUIModal
      title={`Add activities for ${user.name} ${date ? ` - ${moment(date).format('dddd DD MMM YYYY')}` : ''}`}
      width={1600}
      height={1000}
      resizable={true}
      draggable={true}
      {...rest}
    >
      <TableTasks
        tableId={tableId}
        enableAddTime={true}
        addTimeToSpecifiedDate={date}
        customUser={user}
        onAddActivities={onAddActivities}
        data={data}
        loading={loading}
        count={count}
      />
    </MUIModal>
  )
}

const pipeInst: Pipe<{ userId: ID }, typeof ModalAddActivitiesForUserView> = pipe()

export const ModalAddActivitiesForUser: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => {
    return {
      user: getResources(state, 'assets', props.userId),
    }
  })
  .request((props) => {
    const { userId } = props
    return resources.assets.fetchOne(userId)
  })
  .renderLoader(() => null)
  .render(({ userId, ...props }) => <ModalAddActivitiesForUserView {...props} />)
