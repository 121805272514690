import { CellText, CellSelect } from 'app/components/Table/Cells';
import moment from 'moment';
import { updateLeave } from 'app/store/reducers/leaves';
export default ({ projects, formatView, deleteLeavesAction, dispatch }) => [
    {
        Header: 'Leaves',
        id: 'leaves',
        columns: [
            CellSelect({
                id: 'status',
                Header: 'Status',
                hiddenable: true,
                noFilterOption: true,
                accessor: 'approvalStatus',
                width: 110,
                actions: (instance, cell) => {
                    if (!formatView) {
                        return ['edit', deleteLeavesAction(instance, cell)];
                    }
                    return [deleteLeavesAction(instance, cell)];
                },
                options: () => [
                    { label: 'Waiting', value: 0 },
                    { label: 'Approved', value: 1 },
                    { label: 'Refused', value: 2 },
                ].map((status) => {
                    return status;
                }),
                reduxSave: {
                    reduxAction: (values) => dispatch(updateLeave(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { approvalStatus: newValue })),
                },
            }),
            CellText({
                id: 'startDate',
                Header: 'Start Date',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                accessor: (item) => moment.utc(item.startDate).format('L LT'),
                width: 130,
            }),
            CellText({
                id: 'endDate',
                Header: 'End Date',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                accessor: (item) => moment.utc(item.endDate).format('L LT'),
                width: 130,
            }),
            CellSelect({
                id: 'project',
                Header: 'Project',
                accessor: 'project',
                width: 150,
                actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                options: () => projects.map((project) => {
                    return { label: project === null || project === void 0 ? void 0 : project.name, value: project === null || project === void 0 ? void 0 : project.id };
                }),
                reduxSave: {
                    reduxAction: (values) => dispatch(updateLeave(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { project: newValue })),
                },
            }),
            CellText({
                id: 'comment',
                Header: 'Comment',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                accessor: 'comment',
                width: 250,
                actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                reduxSave: {
                    reduxAction: (values) => dispatch(updateLeave(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { comment: newValue })),
                },
            }),
        ],
    },
];
