var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import { map, find, values, reduce } from 'lodash';
import moment from 'moment';
import { getResources } from 'app/store/selectors';
import { TableRedux } from 'app/components/Table/TableRedux';
import { router as routerTask } from 'app/containers/Task/router';
import { confirmDelete, openModal } from 'app/components/Modal';
import { ModalAddCustomActivity } from 'app/pages/MyHours/ModalAddCustomActivity';
import history from 'app/main/routerHistory.js';
import { ModalAddActivitiesForUser } from '../ModalAddActivitiesForUser.jsx';
import { ModalAddAbsenceActivity } from '../../../MyHours/ModalAddAbsenceActivity';
import { cleanUserActivities, deleteActivity, fetchUserActivities } from '../../../../store/reducers/reduxActivities';
import { useDispatch, useSelector } from 'react-redux';
import { userPrefLocalResource } from '../../../../core/utils/localUserPrefs.js';
import getColumns from './columns';
import { useLocation } from 'react-router-dom';
const tableId = 'table-time-recap-activities';
const TableTimeRecapActivities = (props) => {
    var _a;
    const { date, userId, projects, type, tableHoursTools, allotment } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const table = (_a = userPrefLocalResource.getData('tables')) === null || _a === void 0 ? void 0 : _a[tableId];
    const progressionStatus = getResources(undefined, 'progressionStatus');
    const isFromMyHours = location.pathname.includes('my-hours');
    const tableUserPrefs = useSelector((state) => state.tablesPrefs.data[tableId]);
    const { data: { ACTIVITY_TYPES: activityTypes }, } = useSelector((state) => state.projectConfig);
    const { selectedUser: { data: userActivities, count, loading: fetchLoading }, } = useSelector((state) => state.reduxActivities);
    const startDate = useMemo(() => moment(date).startOf('day'), [date]);
    const endDate = useMemo(() => moment(date).endOf('day'), [date]);
    const [updatingKey, setUpdatingKey] = useState();
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(fetchUserActivities({
                userId,
                queries: {
                    date__range: `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`,
                    page_size: (tableUserPrefs === null || tableUserPrefs === void 0 ? void 0 : tableUserPrefs.pageSize) || (table === null || table === void 0 ? void 0 : table.pageSize) || 50,
                },
            }));
        }))();
    }, [userId, date, tableUserPrefs]);
    useEffect(() => {
        return () => {
            dispatch(cleanUserActivities());
        };
    }, []);
    function defaultActions(instance, cell) {
        var _a;
        if (!(cell === null || cell === void 0 ? void 0 : cell.value))
            return [];
        const { taskInst } = cell.value;
        const { getLastestSelection } = ((_a = instance.getLastestInstance) === null || _a === void 0 ? void 0 : _a.call(instance)) || instance;
        const { selectedCells } = getLastestSelection();
        const toDeleteActivities = map(selectedCells, (cell) => cell.row.original.id);
        const nbOfActivities = toDeleteActivities.length;
        return [
            {
                label: `Delete ${nbOfActivities > 1 ? `${nbOfActivities} ` : ''}activit${nbOfActivities > 1 ? 'ies' : 'y'}`,
                onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                    return confirmDelete({
                        render: `Are you sur you want to delete ${nbOfActivities > 1 ? 'these' : 'this'} ${nbOfActivities > 1 ? 'activities' : 'activity'} ?`,
                        onValidate: () => __awaiter(this, void 0, void 0, function* () {
                            yield dispatch(deleteActivity({ activitiesIds: toDeleteActivities, isFromMyHours }));
                        }),
                        validateMessage: `${nbOfActivities > 1 ? 'Activities' : 'Activity'} deleted`,
                    });
                }),
                editAction: true,
            },
            { separator: true },
            {
                label: 'Show task details',
                onClick: (e) => routerTask.goTo('index', { taskId: taskInst.id }, { rightPanel: true }),
                editAction: false,
            },
        ];
    }
    const columns = useMemo(() => getColumns({ projects, defaultActions, activityTypes, progressionStatus, isFromMyHours, dispatch }), [projects, progressionStatus]);
    function onAddActivities(instance, activity) {
        if (!tableHoursTools)
            return;
        const { updateTable, getMetadataFromActivity } = tableHoursTools || {};
        if (updateTable)
            updateTable();
        const { date: activityDate, user, labelRowConcat } = getMetadataFromActivity(activity);
        if (type === labelRowConcat && date.format('YYYY-MM-DD') === activityDate && userId === user) {
            setUpdatingKey(Date.now());
        }
        else {
            history.pushWithQuery({
                selectedUser: user,
                selectedDay: moment(activityDate).format('D'),
                selectedRow: labelRowConcat,
            });
        }
    }
    const ToggleButtons = (instance) => !allotment
        ? [
            {
                key: 'custom',
                onClick: () => openModal(_jsx(ModalAddCustomActivity, { useRedux: true, userId: userId, preSelectedDate: date.toDate() })),
                icon: 'fas-calendar-plus',
                label: 'Add a custom activity',
                options: [
                    {
                        key: 'add',
                        onClick: () => openModal(_jsx(ModalAddActivitiesForUser, { date: date.toDate(), userId: userId, onAddActivities: (activities) => onAddActivities(instance, activities) })),
                        icon: 'fas-plus',
                        label: 'Add an activity',
                    },
                    {
                        key: 'leave',
                        icon: 'fas-clock',
                        onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: userId, preSelectedDate: date.toDate(), onChange: (activities) => onAddActivities(instance, activities), showDuration: true })),
                        label: 'Add a leave',
                    },
                ],
            },
        ]
        : [
            {
                key: 'custom',
                onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: userId, preSelectedDate: date.toDate(), onChange: (activities) => onAddActivities(instance, activities), showDuration: true })),
                icon: 'fas-calendar-plus',
                label: 'Add a leave',
            },
        ];
    function formatRowData(activity) {
        var _a, _b, _c, _d;
        if (allotment) {
            if (((_b = (_a = activity === null || activity === void 0 ? void 0 : activity.taskInst) === null || _a === void 0 ? void 0 : _a.taskTypeInst) === null || _b === void 0 ? void 0 : _b.name) === 'alt')
                return activity;
            return [];
        }
        if (((_d = (_c = activity === null || activity === void 0 ? void 0 : activity.taskInst) === null || _c === void 0 ? void 0 : _c.taskTypeInst) === null || _d === void 0 ? void 0 : _d.name) === 'alt')
            return [];
        if (!type || type === 'total')
            return activity;
        if (type === 'otherActivities' && !activity.project)
            return [];
        if (type === 'otherProjects')
            return [];
        const project = find(projects, (project) => project.name === type);
        if (project && activity.project !== project.id)
            return [];
        return activity;
    }
    const stateResources = reduce(userActivities, (acc, row, id) => {
        const formatedData = formatRowData(row);
        if (Array.isArray(formatedData)) {
            formatedData.forEach((item) => {
                acc[item.id] = item;
            });
            return acc;
        }
        return Object.assign(Object.assign({}, acc), { [id]: formatedData });
    }, {});
    return (_jsx(TableRedux, { tableId: tableId, data: values(stateResources), afterUpdate: tableHoursTools === null || tableHoursTools === void 0 ? void 0 : tableHoursTools.updateTable, toggleButtons: ToggleButtons, columns: columns, loading: fetchLoading, count: count }));
};
export default TableTimeRecapActivities;
