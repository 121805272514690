import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from 'react-router-dom';
import { getImpersonificationUser } from 'app/pages/Admin/Impersonification/impersonificationUser';
import { Container, Nav, LeftContent, RightContent } from 'app/components/LeftNav';
import { Permission } from 'app/containers/Permissions/index.js';
import { NavTitle } from 'app/components/LeftNav/NavTitle.jsx';
import Impersonification from './Impersonification/Impersonification';
import Messages from './Messages/Messages';
import Assets from './Assets/Assets.jsx';
import { ProjectsList } from './ProjectsList/ProjectsList';
import { Groups } from './Groups/Groups.jsx';
import { StatusEditing } from './StatusEditing/StatusEditing.jsx';
import TokensManagement from './TokensManagement/TokensManagement';
export default function () {
    const impersonificationUser = getImpersonificationUser();
    // if user is an imitator, admin is set to false in order to access admin pages
    const admin = impersonificationUser;
    return (_jsx(Permission, { isAdmin: admin, actions: [''], children: _jsxs(Container, { "data-testid": "page-admin", children: [_jsx(LeftContent, { children: (isOpen) => (_jsxs(Nav, { children: [_jsx(NavTitle, { label: "Impersonification", to: "/admin/impersonification", icon: "fas-user-secret" }), _jsx(NavTitle, { label: "Messages", to: "/admin/messages", icon: "fas-envelope" }), _jsx(NavTitle, { label: "Groups", to: "/admin/groups", icon: "fas-users" }), _jsx(NavTitle, { label: "Assets", to: "/admin/assets", icon: "fas-cubes" }), _jsx(NavTitle, { label: "Projects list", to: "/admin/projects", icon: "fas-th-list" }), _jsx(NavTitle, { label: "Status editing", to: "/admin/status", icon: "fas-flag" }), _jsx(NavTitle, { label: "Tokens management", to: "/admin/tokens", icon: "fas-key" })] })) }), _jsx(RightContent, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/admin/impersonification", component: (props) => _jsx(Impersonification, Object.assign({}, props)) }), _jsx(Route, { path: "/admin/messages", component: Messages }), _jsx(Route, { path: "/admin/groups/:groupId?", component: Groups }), _jsx(Route, { path: "/admin/assets", component: Assets }), _jsx(Route, { path: "/admin/projects", component: ProjectsList }), _jsx(Route, { path: "/admin/status", component: StatusEditing }), _jsx(Route, { path: "/admin/tokens", component: TokensManagement })] }) })] }) }));
}
