/** @flow */
import React from 'react'
import { map } from 'lodash'
import type { ID } from 'app/core/types'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import type { CellInstance } from '../../types'
import { Container } from './Container.jsx'

type Props = {|
  id: ID,
  instance: CellInstance,
|}

export function CellStatsCalculatedFields(props: Props): React$Node {
  const { instance, id } = props

  if (!instance || !instance.stats) return null

  const { stats } = instance

  const dynamicApprovalStats = stats[`calculatedField_${id}`]

  if (!dynamicApprovalStats) return null

  const reduceDecimal = (num) => Number(parseFloat(num).toFixed(2))

  return (
    <Container style={{ paddingTop: 10 }}>
      {map(dynamicApprovalStats, (value, key: string) => (
        <div key={key}>
          <span className="bold">{key}:</span>
          <span className="marginLeft5">{durationStr(reduceDecimal(value))}</span>
        </div>
      ))}
    </Container>
  )
}
