// $FlowFixMe
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import resources from 'app/store/resources';
export default (_a) => __awaiter(void 0, [_a], void 0, function* ({ projectId, initProgresses, updateProgress }) {
    const params = (progressKey) => ({
        params: {
            queries: { page_size: 1000 },
            headers: { [window.OVM_PROJECT_HEADER]: projectId || '' },
            getHttpProgress: (p) => updateProgress(progressKey, p),
        },
    });
    let projectPromises = [];
    if (projectId) {
        projectPromises = [
            resources.assets.fetchEpisodes(projectId, params('fetchEpisodes')),
            resources.stepProjects.fetchByProject(projectId, {
                params: { getHttpProgress: (p) => updateProgress('stepProjects-fetchByProject', p) },
            }),
        ];
    }
    initProgresses();
    yield Promise.all([
        ...projectPromises,
        resources.progressionStatus.fetchAll(params('progressionStatus')),
        resources.assetsCustomAttributValues.fetch('category', {
            params: { getHttpProgress: (p) => updateProgress('assetsCustomAttributValues', p) },
        }),
        resources.steps.fetchAll(params('steps')),
        resources.attributes.fetchAll(params('attributes')),
        resources.assets.fetchAll({
            params: {
                queries: { page_size: 1000, assetType: 'pj' },
                getHttpProgress: (p) => updateProgress('assets-pj', p),
            },
        }),
    ]);
    initProgresses();
});
