export const sessionStorageKey = 'impersonification';
export const getImpersonificationUser = () => {
    const itemStr = window.sessionStorage.getItem(sessionStorageKey);
    return itemStr && JSON.parse(itemStr);
};
export const setImpersonificationUser = (user) => {
    window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(user));
};
export const deleteImpersonificationUser = () => {
    window.sessionStorage.removeItem(sessionStorageKey);
};
