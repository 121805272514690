/** @flow */
import React, { useState, useEffect } from 'react'
import { filter } from 'lodash'
import moment from 'moment'
import { getResources } from 'app/store/selectors/getResources'
import resources from 'app/store/resources'
import type { ID, Activity, Asset, ResourcesList } from 'app/core/types'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { useDispatch } from 'react-redux'
import { createActivity, updateActivity } from '../../../store/reducers/reduxActivities'

type Props = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  userId: ?ID,
  taskIds: Array<ID>,
  addTimeToSpecifiedDate?: Date,
  onChange?: (Array<Activity>) => void,
  showUserSelect?: ?boolean,
|}

export function AddTimeModal(props: Props): React$Node {
  const { taskIds, userId, addTimeToSpecifiedDate = new Date(), onChange, showUserSelect, ...modalProps } = props

  const dispatch = useDispatch()

  const [user, setUser] = useState<Asset | void>()
  const [isInit, setIsInit] = useState<boolean>(!userId)
  const [activities, setActivities] = useState<ResourcesList<Activity> | void>()

  useEffect(() => {
    if (userId) {
      resources.assets.fetchOne(userId).then((res) => {
        setUser(res.resources[0])
        setIsInit(true)
      })
    }
  }, [userId])

  useEffect(() => {
    if (user) {
      resources.activities
        .fetchAll({
          params: { queries: { user: user.id, task: taskIds, page_size: 1000 } },
        })
        .then(() => {
          setActivities(
            getResources<ResourcesList<Activity>>(
              undefined,
              'activities',
              (activity) => activity.user === user.id && taskIds.indexOf(activity.task) !== -1,
            ),
          )
        })
    }
  }, [user])

  async function onSave(data) {
    const { duration, assetUser, comment } = data
    const date = moment(data.date).format('YYYY-MM-DD')
    const toUpdate: Array<$Shape<Activity>> = []
    const toCreate: Array<$Shape<Activity>> = []

    const splitedDuration = Math.round(duration / taskIds.length)

    taskIds.forEach((task) => {
      const activity = filter(activities, (activity) => activity.date === date && activity.task === task)[0]
      if (activity) toUpdate.push({ ...activity, duration: activity.duration + splitedDuration, comment })
      else toCreate.push({ duration: splitedDuration, task, date, user: user?.id || assetUser.value, comment })
    })

    const promises = []

    if (toCreate.length > 0) {
      const create = dispatch(createActivity({ createdActivities: toCreate, isFromMyHours: false }))
      promises.push(create)
    }
    if (toUpdate.length > 0) {
      const update = dispatch(updateActivity({ activities: toUpdate, isFromMyHours: false }))
      promises.push(update)
    }

    const res = await Promise.all(promises)
    return res
  }

  return (
    <ModalConfirmForm
      title={`Add activity${user ? ` for ${user.name}` : ''}`}
      validateLabel="Add spent time"
      minWidth={500}
      draggable={true}
      {...modalProps}
    >
      <FormData
        key={String(isInit)}
        defaultData={{
          duration: 0,
          date: addTimeToSpecifiedDate.toISOString(),
          assetUser: user ? { label: user.name, value: user.id } : undefined,
        }}
        properties={[
          ...(!userId || showUserSelect
            ? [
                {
                  key: 'assetUser',
                  label: 'User',
                  type: 'autocomplete',
                  element: AssetsSelect,
                  elementProps: (state, setData) => ({
                    loading: !isInit,
                    isRequired: true,
                    assetTypes: ['us'],
                    onChange: (value) => {
                      setData({ assetUser: value })
                      setUser(value.data)
                    },
                  }),
                },
              ]
            : []),
          {
            key: 'duration',
            label: 'Duration',
            type: 'duration',
            elementProps: {
              isRequired: true,
            },
          },
          {
            key: 'date',
            label: 'Date',
            type: 'dateTime',
            elementProps: {
              isRequired: true,
              max: new Date().toISOString(),
            },
          },
          {
            key: 'comment',
            label: 'Comment',
            type: 'string',
          },
        ]}
        onSave={onSave}
      />
    </ModalConfirmForm>
  )
}
