var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo, useState, useEffect } from 'react';
import { map, uniq } from 'lodash';
import { confirmDelete, openModal } from 'app/components/Modal';
import { ModalAddCustomActivity } from './ModalAddCustomActivity';
import { ModalAddAbsenceActivity } from './ModalAddAbsenceActivity';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { TableRedux } from 'app/components/Table/TableRedux';
import getColumns from './columns';
import { deleteActivity } from 'app/store/reducers/reduxActivities';
const tableId = 'my-hours-custom-activities';
export function TableMyHoursCustomActivities(props) {
    const { user, projects, onChange, getInstance, selectedWeek, formatView } = props;
    const dispatch = useDispatch();
    const [urlStartDate, setUrlStartDate] = useState('');
    const [urlEndDate, setUrlEndDate] = useState('');
    const [paginatedList, setPaginatedList] = useState();
    const { loggedUserActivities: { data: activities = [], count }, } = useSelector((state) => state.reduxActivities);
    const { data: config } = useSelector((state) => state.projectConfig);
    useEffect(() => {
        const startDate = moment(selectedWeek).startOf(formatView);
        const endDate = moment(selectedWeek).endOf(formatView);
        setUrlStartDate(startDate.format('YYYY-MM-DD'));
        setUrlEndDate(endDate.format('YYYY-MM-DD'));
    }, [formatView, selectedWeek]);
    const deleteActivitiesAction = (instance, cell) => ({
        label: 'Delete activity',
        editAction: true,
        onClick: (event) => {
            const { selectedCells } = instance.getLastestSelection();
            const toDeleteActivities = uniq(map(selectedCells, (cell) => cell.row.original.id));
            const nbOfActivities = toDeleteActivities.length;
            return confirmDelete({
                render: `Are you sur you want to delete ${nbOfActivities > 1 ? 'these' : 'this'} ${nbOfActivities > 1 ? 'activities' : 'activity'} ?`,
                onValidate: () => __awaiter(this, void 0, void 0, function* () {
                    yield dispatch(deleteActivity({ activitiesIds: toDeleteActivities, isFromMyHours: true }));
                    instance === null || instance === void 0 ? void 0 : instance.focusCell(undefined);
                }),
                validateMessage: `${nbOfActivities > 1 ? 'Activities' : 'Activity'} deleted`,
            });
        },
    });
    const columns = useMemo(() => getColumns({ deleteActivitiesAction, projects, config, isFromMyHours: true, dispatch }), []);
    const ToggleButtons = (instance) => [
        {
            key: 'custom',
            onClick: () => openModal(_jsx(ModalAddCustomActivity, { useRedux: true, userId: user.asset, paginatedList: paginatedList || '', preSelectedDate: new Date() })),
            icon: 'fas-calendar-plus',
            label: 'Add a custom activity',
            options: [
                {
                    key: 'leave',
                    icon: 'fas-clock',
                    onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: user.asset, preSelectedDate: new Date(), onChange: (activities) => {
                            const { setStateData, data } = instance;
                            const newData = [...data, ...activities];
                            setStateData(newData.reduce((acc, activity) => {
                                acc[activity.id] = activity;
                                return acc;
                            }, {}));
                            onChange === null || onChange === void 0 ? void 0 : onChange(activities);
                        }, showDuration: true })),
                    label: 'Add a leave (unique day)',
                },
                {
                    key: 'leaveDays',
                    icon: 'fas-clock',
                    onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: user.asset, preSelectedDate: new Date(), onChange: (activities) => {
                            const { setStateData, data } = instance;
                            const newData = [...data, ...activities];
                            setStateData(newData.reduce((acc, activity) => {
                                acc[activity.id] = activity;
                                return acc;
                            }, {}));
                            onChange === null || onChange === void 0 ? void 0 : onChange(activities);
                        }, showDuration: true, period: true })),
                    label: 'Add a leave (several days)',
                },
            ],
        },
    ];
    return (_jsx(TableRedux, { tableId: tableId, columns: columns, data: activities === null || activities === void 0 ? void 0 : activities.filter((act) => !act.task), count: count, toggleButtons: ToggleButtons, onResourcesRequested: onChange, getInstance: getInstance }));
}
