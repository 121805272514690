var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { updateDataFromCollection } from './utils';
import { setAllTakes } from './takes';
import { setAllTakesFlags } from './takesFlags';
import { setAssetsFromTasks } from './assets';
const initialState = {
    data: [],
    stats: null,
    loading: false,
    count: 0,
    statsIsLoading: false,
    httpProgress: 0,
};
export const fetchAllTasks = createAsyncThunk('reduxTasks/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.tasks.fetchAll(Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }), {}, (progress) => {
            // dispatch(setHttpProgress(progress))
        });
        const takes = res.results.flatMap((task) => task.takesInst);
        const takesFlags = takes.flatMap((take) => take.rel_takeFlagsInst);
        const assetInst = res.results.flatMap((task) => task.assetInst);
        dispatch(setAllTakes(takes));
        dispatch(setAllTakesFlags(takesFlags));
        dispatch(setAssetsFromTasks(assetInst));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchAllTasksStats = createAsyncThunk('reduxTasksStats/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.tasks.fetchAll({ statMode: 'statsOnly' });
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchUserTasks = createAsyncThunk('reduxTasks/fetchUser', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ userId, queries }, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.tasks.fetchAll(Object.assign({ assignedUser: userId }, queries));
        const takes = res.results.flatMap((task) => task.takesInst);
        const takesFlags = takes.flatMap((take) => take.rel_takeFlagsInst);
        const assetInst = res.results.flatMap((task) => task.assetInst);
        dispatch(setAllTakes(takes));
        dispatch(setAllTakesFlags(takesFlags));
        dispatch(setAssetsFromTasks(assetInst));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateTask = createAsyncThunk('reduxTasks/update', (updatedTask_1, _a) => __awaiter(void 0, [updatedTask_1, _a], void 0, function* (updatedTask, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.tasks.collection.update(updatedTask);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'reduxTasks',
    initialState,
    reducers: {
        updateTaskFromWS: (state, { payload }) => {
            const updatedPayload = Object.assign(Object.assign({}, payload), { id: payload.uuid });
            delete updatedPayload.uuid;
            const newData = state.data.map((task) => {
                if (task.id === updatedPayload.id) {
                    return Object.assign(Object.assign({}, task), updatedPayload);
                }
                return task;
            });
            state.data = newData;
        },
        // setHttpProgress: (state, { payload }) => {
        //   return {
        //     ...state,
        //     httpProgress: payload,
        //   }
        // },
    },
    extraReducers: (builder) => {
        // FETCH ALL TASKS
        builder.addCase(fetchAllTasks.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllTasks.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.count = payload.count;
            state.loading = false;
        });
        builder.addCase(fetchAllTasks.rejected, (state, { payload }) => {
            state.loading = false;
        });
        // FETCH ALL TASKS STATS
        builder.addCase(fetchAllTasksStats.pending, (state) => {
            state.statsIsLoading = true;
        });
        builder.addCase(fetchAllTasksStats.fulfilled, (state, { payload }) => {
            state.stats = payload.stats;
            state.statsIsLoading = false;
            state.loading = false;
        });
        builder.addCase(fetchAllTasksStats.rejected, (state, { payload }) => {
            state.statsIsLoading = false;
        });
        // FETCH USER TASKS
        builder.addCase(fetchUserTasks.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserTasks.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.count = payload.count;
            state.loading = false;
        });
        builder.addCase(fetchUserTasks.rejected, (state, { payload }) => {
            state.loading = false;
        });
        // UPDATE TASK
        builder.addCase(updateTask.pending, (state) => { });
        builder.addCase(updateTask.fulfilled, (state, { payload }) => {
            const newData = updateDataFromCollection(state.data, payload);
            state.data = newData;
        });
        builder.addCase(updateTask.rejected, (state, { payload }) => {
            state.loading = false;
        });
    },
});
export const { updateTaskFromWS } = actions;
export default reducer;
