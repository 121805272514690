// $FlowFixMe
import { useEffect } from 'react';
import { getImpersonificationUser } from './impersonificationUser';
import { createLocalMessage } from 'app/store/reducers/messages';
import { useDispatch } from 'react-redux';
export const idMessageNotif = 'impersonification';
const ImpersonificationStart = () => {
    const dispatch = useDispatch();
    const startImpersonification = (id, email) => {
        dispatch(createLocalMessage({
            id: idMessageNotif,
            text: `<a href="/admin/impersonification">Vous êtes connecté en tant que <b>${email}</b></a>`,
            messageType: 1,
            disablePermanentHide: true,
            disableHide: true,
            data: { id, email },
        }));
    };
    useEffect(() => {
        const user = getImpersonificationUser();
        if (user) {
            startImpersonification(user.id, user.email);
        }
    }, []);
    return null;
};
export default ImpersonificationStart;
