import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import resources from 'app/store/resources';
import { useSelector } from 'react-redux';
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js';
import { MUIButton } from 'app/components/Form';
import { ModalTrigger, confirmDelete } from 'app/components/Modal';
import { getResources } from 'app/store/selectors/getResources';
import { map, sortBy, filter, uniq } from 'lodash';
import { Permission } from 'app/containers/Permissions/Permission.jsx';
import Widget from 'app/components/Widget/Widget.jsx';
import Loader from 'app/components/Loader/Loader.jsx';
import useDebounce from 'app/hooks/useDebounce';
import Tooltip from '@material-ui/core/Tooltip';
import EditFlagModal from './EditFlagModal.jsx';
import classes from './Flags.module.scss';
const categories = {
    article: 'article',
    take: 'take',
    dynApproval: 'dynamic approval value',
    ep: 'episode',
    fo: 'folder',
    mo: 'model',
    sh: 'shot',
    pj: 'project',
    dynamicApprovalValue: 'dynamic approval value',
    post: 'post',
};
export default function FlagsComponent(props) {
    const { category, searchInput } = props;
    const flags = useSelector((state) => getResources(state, 'flags', { category }));
    const allFlags = useSelector((state) => getResources(state, 'flags'));
    const [isLoading, setIsLoading] = useState(false);
    const debouncedSearchTerm = useDebounce(searchInput, 300);
    const searchFlags = useMemo(() => {
        return filter(allFlags, (flag) => flag.name.includes(debouncedSearchTerm));
    }, []);
    useEffect(() => {
        setIsLoading(true);
        searchInput
            ? resources.flags.search({ query: debouncedSearchTerm }).then(() => {
                setIsLoading(false);
            })
            : resources.flags
                .fetchFlags({
                params: { queries: { category } },
            })
                .then(() => {
                setIsLoading(false);
            });
    }, [searchInput]);
    function onDelete(flag) {
        confirmDelete({
            title: 'Delete',
            render: (_jsxs("div", { children: ["Do you want to delete ", _jsx("b", { children: flag.name }), " ?"] })),
            validateLabelColor: '#E56D7A',
            validateLabel: 'Delete',
            onValidate: () => resources.flags.delete(flag.id),
            validateMessage: 'Flag deleted',
        });
    }
    const categoriesSearchFlags = uniq(map(sortBy(searchFlags, ['category']), (flag) => categories[flag.category]));
    const searchFlagsByCategories = categoriesSearchFlags.map((cat) => map(filter(searchFlags, (flag) => {
        return categories[flag.category] === cat;
    }), (flag) => ({
        cat,
        flag,
    })));
    if (isLoading)
        return _jsx(Loader, {});
    return (_jsxs(Widget, { className: classes.flagsContainer, style: { width: '100%' }, children: [_jsx("div", { className: classes.newFlag, children: _jsx(Permission, { actions: ['projet_flags__update'], children: _jsx(ModalTrigger, { modal: _jsx(EditFlagModal, { category: category, categories: categories, searchInput: searchInput }), children: _jsxs(MUIButton, { icon: "fas-plus", style: { marginLeft: 5 }, children: ["Create a new ", categories[category], " flag"] }) }) }) }), !isLoading && !searchInput && Object.keys(flags).length === 0 ? (_jsx("div", { className: classes.noFlag, children: "No flag" })) : !isLoading && searchInput && searchFlags.length === 0 ? (_jsx("div", { className: classes.noFlag, children: "No search result" })) : !searchInput ? (_jsx("div", { className: classes.flags, children: map(sortBy(flags, (flag) => {
                    return flag.name.toLowerCase();
                }), (flag) => (_jsxs("div", { style: {
                        backgroundColor: flag.color,
                        color: getColorFromBackground(flag.color),
                    }, className: classes.flagsItems, children: [_jsx(Tooltip, { title: `Description: ${flag.description || 'No description'}`, children: _jsx("span", { className: classes.flag, children: flag.name }) }), _jsx(Permission, { actions: ['projet_flags__update'], children: _jsx(ModalTrigger, { modal: _jsx(EditFlagModal, { flag: flag, category: flag.category, color: flag.color, categories: categories, searchInput: searchInput }), children: _jsx(MUIButton, { style: {
                                        color: getColorFromBackground(flag.color),
                                        minWidth: '20px',
                                        minHeight: '20px',
                                    }, className: classes.icon, size: "small", variant: "text", icon: "fas-edit", dataCy: `flags-edit-${flag.name}` }) }) }), _jsx(Permission, { actions: ['projet_flags__update'], children: _jsx(MUIButton, { style: {
                                    color: getColorFromBackground(flag.color),
                                    minWidth: '20px',
                                    minHeight: '20px',
                                }, className: classes.icon, icon: "fas-trash", size: "small", variant: "text", onClick: () => onDelete(flag), dataCy: `flags-delete-${flag.name}` }) })] }, flag.id))) })) : (_jsx("div", { style: { overflowY: 'auto', height: '100%' }, children: searchFlagsByCategories.map((array) => (_jsxs(_Fragment, { children: [_jsx("h3", { children: `${array[0].cat[0].toUpperCase() + array[0].cat.slice(1)} (${array.length}) :` }), _jsx("div", { className: classes.flags, style: { justifyContent: 'flex-start', margin: '0', width: '100%' }, children: array.map((flag) => (_jsxs("div", { style: {
                                    backgroundColor: flag.flag.color,
                                    color: getColorFromBackground(flag.flag.color),
                                }, className: classes.flagsItems, children: [_jsx(Tooltip, { title: `Description: ${flag.flag.description || 'No description'}`, children: _jsx("span", { className: classes.flag, children: flag.flag.name }) }), _jsx(Permission, { actions: ['projet_flags__update'], children: _jsx(ModalTrigger, { modal: _jsx(EditFlagModal, { flag: flag.flag, category: flag.flag.category, color: flag.flag.color, categories: categories, searchInput: searchInput }), children: _jsx(MUIButton, { style: {
                                                    color: getColorFromBackground(flag.flag.color),
                                                    minWidth: '20px',
                                                    minHeight: '20px',
                                                }, className: classes.icon, size: "small", variant: "text", icon: "fas-edit", dataCy: `flags-edit-${flag.flag.name}` }) }) }), _jsx(Permission, { actions: ['projet_flags__update'], children: _jsx(MUIButton, { style: {
                                                color: getColorFromBackground(flag.flag.color),
                                                minWidth: '20px',
                                                minHeight: '20px',
                                            }, className: classes.icon, icon: "fas-trash", size: "small", variant: "text", onClick: () => onDelete(flag.flag), dataCy: `flags-delete-${flag.flag.name}` }) })] }, flag.flag.id))) })] }))) }))] }));
}
