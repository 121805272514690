import { createSelector } from '@reduxjs/toolkit';
import { takesFlagsDataSelector } from './takesFlags';
import { flagsDataSelector } from './flags';
export const takesReducer = (state) => state.reduxTakes;
export const takesDataSelector = createSelector(takesReducer, (selector) => selector.data);
export const takesDataWithFlagsRelations = createSelector([takesDataSelector, takesFlagsDataSelector, flagsDataSelector], (takes, takesFlags, allFlags) => {
    // create relation with takesFlags reducer and flags reducer
    const takeWithRelatedFlags = takes.map((take) => {
        const relatedTakesFlags = takesFlags.filter((takeFlag) => takeFlag.take === take.id);
        const relatedFlags = relatedTakesFlags.map((takeFlag) => {
            var _a;
            return ((_a = allFlags.find((flag) => flag.id === takeFlag.flag)) !== null && _a !== void 0 ? _a : take.flagsInst.find((flag) => flag.id === takeFlag.flag));
        });
        return Object.assign(Object.assign({}, take), { rel_takeFlagsInst: relatedTakesFlags, flagsInst: relatedFlags });
    });
    return takeWithRelatedFlags;
});
