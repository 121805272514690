/** @flow */
import React from 'react'
import { deleteImpersonificationUser } from 'app/pages/Admin/Impersonification/impersonificationUser.ts'
import { openModal, confirmDelete } from 'app/components/Modal'
import { ReportingModal } from 'app/containers/Reporting/ReportingModal.jsx'
import { permission } from 'app/containers/Permissions/Permission.jsx'
import settings from 'app/core/settings'
import { useSelector } from 'react-redux'
import { NavMenuItem } from './NavMenuItem.jsx'
import vars from '../../../styles/vars'

import classes from './Header.module.scss'

export default function User(): React$Node {
  const user = useSelector((state) => state.user)

  if (!user?.assetInst) return null

  const {
    assetInst: { name, thumbnailInst },
  } = user

  function onLogout() {
    deleteImpersonificationUser()
    window.location.href = '/authentication/logout'
  }

  function renderUserLogo() {
    let logo = <div style={{ textTransform: 'capitalize' }}>{name}</div>

    if (thumbnailInst && thumbnailInst.url) {
      logo = (
        <div className="flex column center alignCenter">
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              border: '2px solid grey',
              background: `center / cover no-repeat url('${thumbnailInst.url}')`,
            }}
          />
        </div>
      )
    }

    return logo
  }

  function renderReportMenu() {
    return {
      label: 'Report',
      icon: 'fas-bug',
      onClick: () => {
        openModal(<ReportingModal user={user} />)
      },
    }
  }

  const navItems = [
    {
      label: 'Reset browser cache',
      onClick: () =>
        confirmDelete({
          title: 'Reseting the browser cache',
          render: 'Are you sure you want to reset the entire browser cache ?',
          onValidate: () => {
            localStorage.clear()
            document.location.reload()
            return Promise.resolve()
          },
          validateLabel: 'Reset',
        }),
      icon: 'fas-trash-alt',
    },
    {
      label: 'Logout',
      onClick: onLogout,
      icon: 'fas-sign-out-alt',
      style: { backgroundColor: vars.colors.red, color: '#ffffff' },
    },
  ]

  if (permission(['Overmind___Reporting'])) {
    navItems.unshift(renderReportMenu())
  }

  if (settings.git?.CI_COMMIT_TAG) {
    navItems.push({
      label: `version ${settings.git.CI_COMMIT_TAG}`,
      disabled: true,
    })
  }

  return (
    <div className={classes.userAccount}>
      <NavMenuItem items={navItems}>{renderUserLogo()}</NavMenuItem>
    </div>
  )
}
