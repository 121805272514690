import cookies from 'js-cookie';
import settings from 'app/core/settings';
import { useDispatch } from 'react-redux';
import { updateTaskFromWS } from 'app/store/reducers/tasks';
import { updateTakeFromWS } from 'app/store/reducers/takes';
import { useEffect, useRef } from 'react';
const useWebsocket = () => {
    const dispatch = useDispatch();
    const token = cookies.get(settings.cookieToken);
    const wsUrl = settings.env.WS_URL;
    const env = settings.env.NODE_ENV;
    const url = `${env === 'development' ? 'ws' : 'wss'}://${wsUrl}/ws/`;
    const connection = useRef({});
    const connect = (ws) => {
        ws.onopen = (event) => {
            console.log('Socket is open', event);
        };
    };
    useEffect(() => {
        const ws = new WebSocket(url, ['Authorization', token]);
        connect(ws);
        ws.onclose = (event) => {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
            setTimeout(() => {
                connect(ws);
            }, 1000);
        };
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const { event: eventType, data: updatedData, model, timestamp } = data;
            switch (eventType) {
                case 'update':
                    const parsedData = JSON.parse(updatedData);
                    switch (model) {
                        case 'Task':
                            dispatch(updateTaskFromWS(parsedData));
                            break;
                        case 'Take':
                            dispatch(updateTakeFromWS(parsedData));
                            break;
                        default:
                            return;
                    }
                    break;
                default:
                    return;
            }
            connection.current = ws;
        };
        return () => ws.close();
    }, []);
};
export default useWebsocket;
