import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    data: {},
    loading: false,
};
const { actions, reducer } = createSlice({
    name: 'tablesPrefs',
    initialState,
    reducers: {
        setTableConfig: (state, { payload }) => {
            const { tableId, property, value, data } = payload;
            state.data = Object.assign(Object.assign({}, state.data), { [tableId]: Object.assign(Object.assign({}, state.data[tableId]), data) });
        },
    },
});
export const { setTableConfig } = actions;
export default reducer;
