import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    loading: true,
};
const { actions, reducer } = createSlice({
    name: 'tablesPrefsLoader',
    initialState,
    reducers: {
        setLoader: (state, { payload }) => {
            state.loading = payload;
        },
    },
    extraReducers: () => { },
});
export const { setLoader } = actions;
export default reducer;
