var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { localResource } from 'app/containers/Notif/localResource';
const initialState = {
    data: [],
    messagesToDisplay: [],
    loading: false,
    count: 0,
};
export const fetchMessages = createAsyncThunk('messages/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.messages.fetchAll();
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const fetchPublicMessages = createAsyncThunk('publicMessages/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.publicMessages();
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const createMessage = createAsyncThunk('messages/create', (message_1, _a) => __awaiter(void 0, [message_1, _a], void 0, function* (message, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.messages.create(message);
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const updateMessage = createAsyncThunk('messages/update', (messages_1, _a) => __awaiter(void 0, [messages_1, _a], void 0, function* (messages, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.messages.collection.update(messages);
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const deleteMessage = createAsyncThunk('messages/delete', (messageId_1, _a) => __awaiter(void 0, [messageId_1, _a], void 0, function* (messageId, { fulfillWithValue, rejectWithValue }) {
    try {
        yield api.messages.delete(messageId);
        return fulfillWithValue(messageId);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
const { reducer, actions } = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        createLocalMessage: (state, { payload }) => {
            state.data = [...state.data, payload];
        },
        hideMessage: (state, { payload }) => {
            const { message, userId } = payload;
            if (!message.disablePermanentHide) {
                localResource.setData(`${message.id}__${userId}.hidden`, true);
            }
            state.messagesToDisplay = state.messagesToDisplay.filter((msg) => msg.id !== message.id);
        },
        setMessagesToDisplay: (state, { payload }) => {
            const { messages, userId } = payload;
            const activeMessages = messages.filter((message) => {
                return !localResource.getData(`${message.id}__${userId}.hidden`, false);
            });
            state.messagesToDisplay = activeMessages;
        },
    },
    extraReducers: (builder) => {
        // FETCH MESSAGES
        builder.addCase(fetchMessages.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMessages.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.loading = false;
            state.count = payload.count;
        });
        builder.addCase(fetchMessages.rejected, (state) => {
            state.loading = false;
        });
        // UPDATE MESSAGE
        builder.addCase(updateMessage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateMessage.fulfilled, (state, { payload }) => {
            if (Array.isArray(payload)) {
                payload.forEach((msg) => {
                    const newData = state.data.map((message) => {
                        if (message.id === msg.id) {
                            return msg;
                        }
                        return message;
                    });
                    const newMessagesToDisplay = state.messagesToDisplay.map((message) => {
                        if (message.id === msg.id) {
                            return msg;
                        }
                        return message;
                    });
                    state.messagesToDisplay = newMessagesToDisplay;
                    state.data = newData;
                });
            }
            else {
                state.data = state.data.map((aa) => {
                    return aa.id === payload.id ? payload : aa;
                });
            }
            state.loading = false;
        });
        builder.addCase(updateMessage.rejected, (state) => {
            state.loading = false;
        });
        // FETCH PUBLIC MESSAGES
        builder.addCase(fetchPublicMessages.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchPublicMessages.fulfilled, (state, { payload }) => {
            state.messagesToDisplay = payload;
            state.loading = false;
        });
        builder.addCase(fetchPublicMessages.rejected, (state) => {
            state.loading = false;
        });
        // CREATE MESSAGE
        builder.addCase(createMessage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createMessage.fulfilled, (state, { payload }) => {
            state.data = [...state.data, payload];
            state.loading = false;
            state.count++;
        });
        builder.addCase(createMessage.rejected, (state) => {
            state.loading = false;
        });
        // DELETE MESSAGE
        builder.addCase(deleteMessage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteMessage.fulfilled, (state, { payload }) => {
            state.data = state.data.filter((msg) => msg.id !== payload);
            state.messagesToDisplay = state.messagesToDisplay.filter((msg) => msg.id !== payload);
            state.loading = false;
            state.count--;
        });
        builder.addCase(deleteMessage.rejected, (state) => {
            state.loading = false;
        });
    },
});
export const { createLocalMessage, hideMessage, setMessagesToDisplay } = actions;
export default reducer;
