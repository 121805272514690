import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { router } from 'app/containers/Assets/AssetDetail/router';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { MUIButton } from 'app/components/Form';
import classes from '../projectView.module.scss';
export const ProjectView = ({ project }) => {
    const { id: projectId, thumbnailInst, name } = project;
    return (_jsxs(Link, { to: `/projects/${projectId}`, className: `${classes.container} flex row`, children: [_jsx("div", { "data-testid": "project-name", children: (thumbnailInst === null || thumbnailInst === void 0 ? void 0 : thumbnailInst.url) ? (_jsx("div", { className: classes.imageContainer, style: { background: `center / cover no-repeat url('${thumbnailInst.url}')` } })) : null }), _jsx("div", { className: "marginLeft10 flex alignCenter spaceBetween", children: _jsx("h2", { className: "fontSize22 bold", children: name }) }), _jsx(MUIButton, { tooltip: "Open asset sheet", dataCy: "open-asset-sheet", style: {
                    minWidth: 25,
                    minHeight: 25,
                    width: 25,
                    height: 25,
                    borderRadius: 25,
                    padding: 0,
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    margin: 'auto 0 auto auto',
                }, children: _jsx(FontIcon, { className: "fontSize25", icon: "fas-info-circle", style: { color: 'grey' }, onClick: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        router.goTo('index', { assetId: projectId }, { rightPanel: true });
                    } }) })] }));
};
