var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    data: [],
    loading: false,
    count: 0,
};
const test = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
};
export const updateAsset = createAsyncThunk('reduxAssets/update', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ updatedAsset, params = {} }, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.assets.update(updatedAsset, {}, params === null || params === void 0 ? void 0 : params.headers);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'reduxTasks',
    initialState,
    reducers: {
        setAssetsFromTasks: (state, { payload }) => {
            const t = [...state.data, ...payload];
            const newData = test(t, (it) => it.id);
            state.data = newData;
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL ASSETS
        // builder.addCase(fetchAllTasks.pending, (state) => {
        //   state.loading = true
        // })
        // builder.addCase(fetchAllTasks.fulfilled, (state, { payload }: PayloadAction<any>) => {
        //   state.data = payload.results
        //   state.count = payload.count
        //   state.loading = false
        // })
        // builder.addCase(fetchAllTasks.rejected, (state, { payload }: PayloadAction<any>) => {
        //   state.loading = false
        // })
        // UPDATE ASSET
        builder.addCase(updateAsset.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateAsset.fulfilled, (state, { payload }) => {
            // const newData = updateDataFromCollection(state.data, payload)
            const newData = state.data.map((asset) => {
                if (asset.id === payload.id) {
                    return payload;
                }
                return asset;
            });
            state.data = newData;
        });
        builder.addCase(updateAsset.rejected, (state, { payload }) => {
            state.loading = false;
        });
    },
});
export const { setAssetsFromTasks } = actions;
export default reducer;
