import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo } from 'react';
import { confirmDelete } from 'app/components/Modal';
import getColumns from './columns';
import MessagesModal from './Modal';
import { TableRedux } from 'app/components/Table/TableRedux';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMessage, fetchMessages } from 'app/store/reducers/messages';
import { messagesReducerSelector } from 'app/store/selectors/messages';
const tableId = 'table-permissionGroup';
const MessagesTable = () => {
    const dispatch = useDispatch();
    const { data, count } = useSelector(messagesReducerSelector);
    useEffect(() => {
        dispatch(fetchMessages());
    }, []);
    function onDelete(row) {
        confirmDelete({
            title: 'Delete',
            render: (_jsxs("p", { children: ["Do you want to delete ", _jsx("b", { children: row.original.title }), " ?"] })),
            onValidate: () => dispatch(deleteMessage(row.original.id)),
            validateMessage: 'Message deleted',
        });
    }
    const columns = useMemo(() => getColumns({ onDelete, dispatch }), []);
    const modal = useMemo(() => MessagesModal, []);
    return (_jsx("div", { style: { flex: 1, display: 'flex', width: '100%', height: '100%' }, children: _jsx(TableRedux, { tableId: tableId, data: data, extendToolbar: modal, columns: columns, loading: false, count: count }) }));
};
export default MessagesTable;
