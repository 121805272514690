import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import { map } from 'lodash';
import Bowser from 'bowser';
import cx from 'classnames';
import settings from 'app/core/settings';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import classes from './Notif.module.scss';
import { setMessagesToDisplay, hideMessage } from '../../store/reducers/messages';
import { useDispatch, useSelector } from 'react-redux';
import { messagesToDisplaySelector } from 'app/store/selectors/messages';
const browser = Bowser.getParser(window.navigator.userAgent);
function Message({ message, userId }) {
    const dispatch = useDispatch();
    const messageTypeClassKey = `messageType_${message.messageType}`;
    const messageTypeClassName = classes[messageTypeClassKey];
    const className = cx([classes.message, messageTypeClassName]);
    const closeBtn = message.disableHide ? null : (_jsx(FontIcon, { icon: "close", className: classes.messageClose, onClick: () => {
            dispatch(hideMessage({ message, userId }));
        } }));
    return (_jsxs("div", { className: className, children: [_jsx("div", { className: classes.messageText, children: typeof message.text === 'string' ? _jsx("span", { dangerouslySetInnerHTML: { __html: message.text } }) : message.text }), closeBtn] }));
}
const Notif = () => {
    const dispatch = useDispatch();
    const { asset: userId } = useSelector((state) => state.user);
    const { data: config } = useSelector((state) => state.projectConfig);
    const messagesToDisplay = useSelector(messagesToDisplaySelector);
    useEffect(() => {
        if (userId) {
            onReceiveConfig();
        }
    }, [userId]);
    const isNotValidBrowser = () => !browser.satisfies({ chrome: '>0', opera: '>0', chromium: '>0' });
    const onReceiveConfig = () => {
        var _a;
        const messages = [];
        if (settings.env.NODE_ENV === 'preproduction') {
            messages.push({
                id: 'preprod',
                title: '',
                text: 'Environment Preproduction',
                messageType: '1',
                disableHide: true,
            });
        }
        if (isNotValidBrowser()) {
            messages.push({
                id: 'validBrowser',
                title: 'Warning',
                text: (_jsxs("span", { "aria-hidden": "true", children: ["The browser you are currently using is not supported by Overmind. If you encounter any problems, please do not contact Overmind support. The browsers currently available are", ' ', _jsx("a", { href: "https://www.google.com/intl/en_en/chrome/", "aria-hidden": "true", children: "Chrome" }), ",", ' ', _jsx("a", { href: "https://www.chromium.org/", "aria-hidden": "true", children: "Chromium" }), ' ', "and", ' ', _jsx("a", { href: "https://www.opera.com/en/download", "aria-hidden": "true", children: "Opera" }), "."] })),
                messageType: '0',
                disableHide: true,
            });
        }
        dispatch(setMessagesToDisplay({ messages: [...(_a = config === null || config === void 0 ? void 0 : config.MESSAGES) !== null && _a !== void 0 ? _a : [], ...messages], userId }));
    };
    if (messagesToDisplay.length === 0)
        return null;
    return (_jsx("div", { className: classes.container, children: map(messagesToDisplay, (message) => (_jsx(Message, { message: message, userId: userId }, message.id))) }));
};
export default Notif;
