var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { updateDataFromCollection } from './utils';
const initialState = {
    data: [],
    loading: false,
    count: 0,
};
export const updateTake = createAsyncThunk('takes/update', (updatedTake_1, _a) => __awaiter(void 0, [updatedTake_1, _a], void 0, function* (updatedTake, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.takes.collection.update(updatedTake);
        return fulfillWithValue(response);
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
const { actions, reducer } = createSlice({
    name: 'reduxTakes',
    initialState,
    reducers: {
        setAllTakes: (state, { payload }) => {
            state.data = payload;
        },
        updateTakeFromWS: (state, { payload }) => {
            const updatedPayload = Object.assign(Object.assign({}, payload), { id: payload.uuid });
            delete updatedPayload.uuid;
            const newData = state.data.map((take) => {
                if (take.id === updatedPayload.id) {
                    return Object.assign(Object.assign({}, take), payload);
                }
                return take;
            });
            state.data = newData;
        },
    },
    extraReducers: (builder) => {
        // UPDATE TAKE
        builder.addCase(updateTake.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateTake.fulfilled, (state, { payload }) => {
            const newData = updateDataFromCollection(state.data, payload);
            state.data = newData;
            state.loading = false;
        });
        builder.addCase(updateTake.rejected, (state) => {
            state.loading = false;
        });
    },
});
export const { setAllTakes, updateTakeFromWS } = actions;
export default reducer;
