// $FlowFixMe
import { colors } from 'app/styles/colors';
export const messagesTypes = [
    {
        value: 0,
        label: 'High',
        backgroundColor: colors.red,
    },
    {
        value: 1,
        label: 'Medium',
        backgroundColor: colors.yellowDark,
    },
    {
        value: 2,
        label: 'Low',
        backgroundColor: colors.blue,
    },
];
export const scopesTypes = [
    { value: 'all', label: 'All users' },
    { value: 'authenticated', label: 'Authenticated' },
    { value: 'unauthenticated', label: 'Unauthenticated' },
];
