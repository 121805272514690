export const updateDataFromCollection = (oldData, elementsToUpdate) => {
    const updatedData = oldData.map((oldEl) => {
        const elementToUpdate = elementsToUpdate.find((newEl) => newEl.id === oldEl.id);
        return elementToUpdate ? Object.assign(Object.assign({}, oldEl), elementToUpdate) : oldEl;
    });
    return updatedData;
};
export const deleteDataFomCollection = (oldData, elementsToDelete) => {
    const updatedData = oldData.filter((el) => !elementsToDelete.includes(el.id));
    return updatedData;
};
