import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MUIButton } from 'app/components/Form';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import classes from './Impersonification.module.scss';
import { idMessageNotif } from './ImpersonificationStart';
import { setImpersonificationUser, deleteImpersonificationUser } from './impersonificationUser';
import { messageByIdSelector } from 'app/store/selectors/messages';
const Impersonification = () => {
    const message = messageByIdSelector(idMessageNotif);
    const { id = '', email = '' } = message;
    const startImpersonification = (id, email) => {
        const user = { email, id };
        setImpersonificationUser(user);
        window.location.href = '/';
    };
    const onChange = (value) => {
        const { email } = value.data.attributes;
        startImpersonification(value.value, email);
    };
    const onDeleteImpersonification = () => {
        deleteImpersonificationUser();
        window.location.reload();
    };
    const label = `Voir l'interface en tant que`;
    return (_jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.top, children: [_jsx("div", { className: classes.label, children: label }), _jsx(AssetsSelect, { assetTypes: ['us'], placeholder: "Search user", value: { label: email, value: id }, onChange: onChange })] }), _jsx(MUIButton, { bgColor: "#E56D7A", onClick: onDeleteImpersonification, children: "Stop Impersonification" })] }));
};
export default Impersonification;
