// $FlowFixMe
import settings from 'app/core/settings';
import { enableBatching } from 'redux-batched-actions';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers/index.js';
const config = {
    key: 'root',
    storage,
    whitelist: ['tablesPrefs', 'tablesFilters'],
};
// TO DO: check if enableBatching is still necessary with Redux Toolkit
const reducer = enableBatching(persistCombineReducers(config, reducers));
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        // Upgrade to Redux toolkit can reveals immutable checks errors. See https://stackoverflow.com/questions/64695464/error-invariant-failed-a-state-mutation-was-detected-between-dispatches-in-th
        immutableCheck: false,
        // Same for serializable checks
        serializableCheck: {
            ignoredActions: ['READ_RESOURCES_SUCCEEDED'],
        },
    }),
});
export default store;
if (['development', 'local'].includes(settings.env.NODE_ENV)) {
    // @ts-ignore
    window.__store = store;
}
