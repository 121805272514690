// @flow

import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api'
import { beforeCreate, beforeUpdate } from 'app/store/hooks/tasks.js'
import type { TasksActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'
import { autocomplete } from './autocomplete.js'

export const tasks: StoreResourceDef<TasksActions<>> = {
  resourceType: 'tasks',
  hooks: (getResourcesAPI, getResources) => ({
    beforeUpdate: beforeUpdate(getResourcesAPI, getResources),
    beforeCreate: beforeCreate(getResourcesAPI, getResources),
  }),
  actions: (getResources) => ({
    fetchByAsset: ({ id }, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .tasks({ id }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(restConfig)),
      }
    },
    history: ({ id }, config) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.tasks
            .history({ id }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(restConfig)),
      }
    },
    fetchUserTasks: (data, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.users
            .myTasks(
              null,
              { ...params.queries, 'taskType__name!': 'alt' },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    fetchUserPastTasks: (data, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.users
            .myPastTasks(
              null,
              { ...params.queries, ...data },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    fetchMyHours: (data, config) => {
      const { params = {}, ...restConfig } = config || {}
      const { startDate, endDate, pastTasks, altTaskType } = data

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return import('app/store/resources').then((resources) => {
            return Promise.all([
              api.users[pastTasks ? 'myPastTasks' : 'myTasks'](
                null,
                { [`taskType__name${!altTaskType ? '!' : ''}`]: 'alt', ...params.queries },
                params.headers,
                params.getHttpProgress,
                params.requestController,
              ).then(transformResults(restConfig)),
            ]).then(([tasks, activities]) => tasks)
          })
        },
      }
    },
    fetchByUser: ({ userId }, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.tasks
            .fetchAll(
              { assignedUser: userId, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    search: autocomplete('task'),
  }),
  relations: {
    activitiesInst: {
      type: 'hasMany',
      resourceType: 'activities',
      foreignKey: 'task',
    },
    approvalWorkflowInst: {
      type: 'hasOne',
      resourceType: 'approvalWorkflows',
      foreignKey: 'approvalWorkflow',
    },
    assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'asset',
    },
    assignedUserInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'assignedUser',
    },
    suggestedUserInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'suggestedUser',
    },
    statusInst: {
      type: 'hasOne',
      resourceType: 'progressionStatus',
      foreignKey: 'status',
    },
    stepInst: {
      type: 'hasOne',
      resourceType: 'steps',
      foreignKey: 'step',
    },
    takesInst: {
      type: 'hasMany',
      resourceType: 'takes',
      foreignKey: 'task',
    },
    thumbnailInst: {
      type: 'hasOne',
      resourceType: 'medias',
      foreignKey: 'thumbnail',
    },
    taskViewersInst: {
      type: 'hasMany',
      resourceType: 'taskViewers',
      foreignKey: 'task',
      serverKey: 'rel_taskViewersInst',
    },
    viewersInst: {
      // Hydrate only
      type: 'hasMany',
      resourceType: 'assets',
    },
  },
}
