/** @flow */
import React from 'react'
import { map, sortBy } from 'lodash'
import pipe from 'app/core/utils/pipe'
import resources from 'app/store/resources'
import { Permission } from 'app/containers/Permissions/index.js'
import { NavMenuItem } from './NavMenuItem.jsx'
import classes from './Header.module.scss'

class Nav extends React.Component<*> {
  componentDidMount() {
    resources.assets.fetchOffices()
  }

  render(): React$Node {
    const { projects } = this.props

    return (
      <nav className={classes.nav}>
        <NavMenuItem
          items={map(sortBy(projects, ['name']), ({ name, id, thumbnailInst }) => {
            const image = thumbnailInst && typeof thumbnailInst === 'object' && thumbnailInst.url
            return { label: name, to: `/projects/${id}`, image }
          })}
        >
          Projects
        </NavMenuItem>
        <Permission actions={['wiki___read']}>
          <NavMenuItem to="/assets-wiki">Wiki</NavMenuItem>
        </Permission>
        <Permission actions={['my tasks___read']}>
          <NavMenuItem to="/my-tasks">My Tasks</NavMenuItem>
        </Permission>
        <Permission
          actions={[
            'human resources_organization_imputations_edit',
            'human resources_organization_imputations_read',
            'human resources_organization_contracts_edit persons informations',
            'human resources_organization_contracts_edit contracts',
            'human resources_organization_contracts_read',
            'human resources_time recap__read',
            'human resources_allotment recap__read',
            'human resources_settings_departments_edit',
            'human resources_settings_departments_read',
            'human resources_settings_posts_edit',
            'human resources_settings_posts_read',
            'human resources_settings_collective agreement titles_edit',
            'human resources_settings_collective agreement titles_read',
            'human resources_settings_person attributes_edit',
            'human resources_settings_person attributes_read',
            'human resources_settings_contract attributes_edit',
            'human resources_settings_contract attributes_read',
          ]}
          operator="or"
        >
          <NavMenuItem to="/hr">Human Resources</NavMenuItem>
        </Permission>
        <Permission actions={[]}>
          <NavMenuItem to="/admin">Admin</NavMenuItem>
        </Permission>
      </nav>
    )
  }
}

const Component: React$ComponentType<any> = pipe()
  .withRouter()
  .enableDeepCompare()
  .connect((state, props) => {
    const projects = map(state.user.projects, (project) => project).filter((_) => _)
    return {
      user: state.user,
      projects,
      pathname: props.history.location.pathname,
    }
  })
  .render(Nav)

export default Component
