/** @flow */
import React from 'react'
import { dynamicApprovalStatus } from 'app/core/constants/dynamicApprovalStatus'
import type { ID } from 'app/core/types'
import type { CellInstance } from '../../types'
import { Container } from './Container.jsx'
import { DisplayStatus } from './DisplayStatus.jsx'

type Props = {|
  instance: CellInstance,
  dynamicApprovalId: ID,
  columnName: string,
|}

export function CellStatsDynamicApprovals(props: Props): React$Node {
  const { instance, dynamicApprovalId, columnName, isCalculatedField = false } = props

  if (!instance || !instance.stats) return <div />

  const {
    stats,
    filtersTools,
    tableId,
    pagination: { totalRowsCount },
  } = instance

  const dynamicApprovalStats = isCalculatedField
    ? stats[`calculatedField_${dynamicApprovalId}`]
    : stats[`dynamicApproval_${dynamicApprovalId}`]

  if (!dynamicApprovalStats) return <div />

  const { status__counts = [] } = dynamicApprovalStats

  const emptyStatusNumber = status__counts.reduce((a, n) => a - n.count, totalRowsCount)

  return (
    <Container>
      <DisplayStatus
        tableId={tableId}
        progressionStatus={dynamicApprovalStatus}
        statusList={[...status__counts, { status: null, count: emptyStatusNumber }]}
        columnName={columnName}
        setFilters={filtersTools?.setUrlFilters}
      />
    </Container>
  )
}
