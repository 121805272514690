var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { deleteDataFomCollection, updateDataFromCollection } from './utils';
import moment from 'moment';
const initialState = {
    fetchLoading: false,
    updateLoading: false,
    // all displayed users activities, ex. time recap view. We don't have all the details (taskInst)
    allUsers: {
        data: [],
        loading: false,
        count: 0,
    },
    // store data for a selected user, ex. in time recap view in TableTimeRecapActivities, or in TableMyHoursRecapActivities
    selectedUser: {
        data: [],
        loading: false,
        count: 0,
    },
    // store data for the logged user
    loggedUserActivities: {
        data: [],
        loading: false,
        count: 0,
    },
    // store logged user today activities (useful for display hours in navbar)
    loggedUserTodayActivities: [],
    error: null,
};
export const fetchAllActivities = createAsyncThunk('reduxActivities/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.activities.fetchAll();
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchUserActivities = createAsyncThunk('reduxActivities/users/fetchAll', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ userId, queries }, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.users.activities({ id: userId }, queries);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchMyActivities = createAsyncThunk('myActivities/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.users.myActivities({}, Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchTodayMyActivities = createAsyncThunk('myTodayActivities/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue }) {
    try {
        const todayDate = moment().format('YYYY-MM-DD');
        const res = yield api.users.myActivities({ date__range: `${todayDate},${todayDate}` }, Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createActivity = createAsyncThunk('reduxActivities/create', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ createdActivities, isFromMyHours }, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.activities.collection.create(createdActivities);
        return fulfillWithValue({ response, isLoggedUserActivity: isFromMyHours });
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateActivity = createAsyncThunk('reduxActivities/update', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ activities, isFromMyHours }, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.activities.collection.update(activities);
        return fulfillWithValue({ response, isLoggedUserActivity: isFromMyHours });
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteActivity = createAsyncThunk('reduxActivities/delete', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ activitiesIds, isFromMyHours }, { fulfillWithValue, rejectWithValue }) {
    try {
        yield api.activities.collection.delete(activitiesIds.map((id) => ({ id })));
        return fulfillWithValue({ activitiesIds, isLoggedUserActivity: isFromMyHours });
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const myAsyncActionCreator = createActivity.fulfilled;
const { actions, reducer } = createSlice({
    name: 'reduxActivities',
    initialState,
    reducers: {
        setActivities: (state, { payload }) => {
            state.allUsers.data = payload;
        },
        cleanUserActivities: (state) => {
            state.selectedUser.data = [];
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL ACTIVITIES
        builder.addCase(fetchAllActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllActivities.fulfilled, (state, { payload }) => {
            state.allUsers.data = payload.results;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // FETCH USER ACTIVITIES
        builder.addCase(fetchUserActivities.pending, (state) => {
            state.selectedUser.loading = true;
        });
        builder.addCase(fetchUserActivities.fulfilled, (state, { payload }) => {
            state.selectedUser.data = payload.results;
            state.selectedUser.loading = false;
            state.selectedUser.count = payload.count;
        });
        builder.addCase(fetchUserActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.selectedUser.loading = false;
        });
        // FETCH MY ACTIVITIES
        builder.addCase(fetchMyActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchMyActivities.fulfilled, (state, { payload }) => {
            state.loggedUserActivities.data = payload.results;
            state.loggedUserActivities.count = payload.count;
            state.fetchLoading = false;
        });
        builder.addCase(fetchMyActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // FETCH TODAY MY ACTIVITIES
        builder.addCase(fetchTodayMyActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchTodayMyActivities.fulfilled, (state, { payload }) => {
            state.loggedUserTodayActivities = payload.results;
            // state.loggedUserActivities.count = payload.count
            state.fetchLoading = false;
        });
        builder.addCase(fetchTodayMyActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // CREATE ACTIVITY
        builder.addCase(createActivity.pending, (state) => { });
        builder.addCase(myAsyncActionCreator, (state, { payload }) => {
            const { response, isLoggedUserActivity } = payload;
            if (isLoggedUserActivity) {
                const todayDate = moment().format('YYYY-MM-DD');
                const todayActivities = response.filter((activity) => activity.date === todayDate);
                state.loggedUserTodayActivities = [...state.loggedUserTodayActivities, ...todayActivities];
                state.loggedUserActivities.data = [...state.loggedUserActivities.data, ...response];
            }
            else {
                state.allUsers.data = [...state.allUsers.data, ...response];
                state.selectedUser.data = [...state.selectedUser.data, ...response];
            }
        });
        builder.addCase(createActivity.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // UPDATE ACTIVITY
        builder.addCase(updateActivity.pending, (state) => {
            state.updateLoading = true;
        });
        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            const { response, isLoggedUserActivity } = payload;
            if (isLoggedUserActivity) {
                const newLoggedUserActivitiesData = updateDataFromCollection(state.loggedUserActivities.data, response);
                const newLoggedUserTodayActivitiesData = updateDataFromCollection(state.loggedUserTodayActivities, response);
                state.loggedUserActivities.data = newLoggedUserActivitiesData;
                state.loggedUserTodayActivities = newLoggedUserTodayActivitiesData;
            }
            else {
                const newData = updateDataFromCollection(state.allUsers.data, response);
                const newselectedUserData = updateDataFromCollection(state.selectedUser.data, response);
                state.allUsers.data = newData;
                state.selectedUser.data = newselectedUserData;
            }
        });
        builder.addCase(updateActivity.rejected, (state, { payload }) => {
            state.error = payload;
            state.updateLoading = false;
        });
        // DELETE ACTIVITY
        builder.addCase(deleteActivity.pending, (state) => { });
        builder.addCase(deleteActivity.fulfilled, (state, { payload }) => {
            const { activitiesIds, isLoggedUserActivity } = payload;
            if (isLoggedUserActivity) {
                const newLoggedUserActivitiesData = deleteDataFomCollection(state.loggedUserActivities.data, activitiesIds);
                const newLoggedUserTodayActivitiesData = deleteDataFomCollection(state.loggedUserTodayActivities, activitiesIds);
                state.loggedUserActivities.data = newLoggedUserActivitiesData;
                state.loggedUserTodayActivities = newLoggedUserTodayActivitiesData;
            }
            const newData = deleteDataFomCollection(state.allUsers.data, activitiesIds);
            const newselectedUserData = deleteDataFomCollection(state.selectedUser.data, activitiesIds);
            state.allUsers.data = newData;
            state.selectedUser.data = newselectedUserData;
        });
        builder.addCase(deleteActivity.rejected, (state, { payload }) => {
            state.error = payload;
        });
    },
});
export const { setActivities, cleanUserActivities } = actions;
export default reducer;
