import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import noImage from 'app/styles/images/defaultImage.png';
const LibraryCard = ({ height, onClick, image, title, showLegend, size, cardContent }) => {
    return (_jsx("div", { className: "fullWidth", onClick: onClick, children: _jsx(Card, { style: { border: '1px solid #656565' }, children: _jsxs(CardActionArea, { children: [_jsx(CardMedia, { height: height, style: {
                            objectFit: 'contain',
                            backgroundColor: '#e5e5e5',
                            padding: size * 4,
                        }, component: 'img', image: image || noImage, title: title }), showLegend ? (_jsx(CardContent, { style: {
                            padding: size === 6 ? '12px' : '8px',
                            minHeight: size === 6 ? '100px' : '92px',
                        }, children: cardContent })) : null] }) }) }));
};
export default LibraryCard;
