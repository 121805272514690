import * as blocklyJavascript from 'blockly/javascript';
function removeTrainlingComma(value) {
    return value.length ? value.trim().slice(0, -1) : value;
}
blocklyJavascript.javascriptGenerator.forBlock.result = function (block, generator) {
    return generator.valueToCode(block, 'base', blocklyJavascript.Order.NONE) || "'empty'";
};
blocklyJavascript.javascriptGenerator.forBlock.number_block = function (block, generator) {
    const dropdown_operation = block.getFieldValue('operation');
    const value_firstoperand = generator.valueToCode(block, 'firstOperand', blocklyJavascript.Order.NONE);
    const value_secondoperand = generator.valueToCode(block, 'secondOperand', blocklyJavascript.Order.NONE);
    const code = `{
  "operation": "${dropdown_operation}",
  "args": [${value_firstoperand}, ${value_secondoperand}],
  "params": {}
  }`;
    return [code, blocklyJavascript.Order.NONE];
};
blocklyJavascript.javascriptGenerator.forBlock.list_block = function (block, generator) {
    const dropdown_operation = block.getFieldValue('operation');
    const value_list = generator.valueToCode(block, 'list', blocklyJavascript.Order.NONE);
    const value_parameters = removeTrainlingComma(generator.statementToCode(block, 'parameters'));
    const code = `{
  "operation": "${dropdown_operation}",
  "args": ${value_list},
  "params": {${value_parameters}}
}`;
    return [code, blocklyJavascript.Order.NONE];
};
blocklyJavascript.javascriptGenerator.forBlock.aggregate_block = function (block, generator) {
    const value_list = generator.valueToCode(block, 'list', blocklyJavascript.Order.NONE);
    const code = `{
  "operation": "aggregate",
  "args": ${value_list},
  "params": {}
  }`;
    return [code, blocklyJavascript.Order.NONE];
};
blocklyJavascript.javascriptGenerator.forBlock.tasks_list = function (block, generator) {
    return [`[${removeTrainlingComma(generator.statementToCode(block, 'steps'))}]`, blocklyJavascript.Order.NONE];
};
blocklyJavascript.javascriptGenerator.forBlock.dynamicApproval_list = function (block, generator) {
    return [`[${removeTrainlingComma(generator.statementToCode(block, 'dynApps'))}]`, blocklyJavascript.Order.NONE];
};
blocklyJavascript.javascriptGenerator.forBlock.step_block = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    const code = `{
      "accessorType": "relation",
      "key": "tasks",
      "filter":  ["step", "${dropdown_step}"]
  },`;
    return code;
};
blocklyJavascript.javascriptGenerator.forBlock.dynamicApproval_block = function (block, generator) {
    const dropdown_dynamicApproval = block.getFieldValue('dynamicApproval');
    const code = `{
      "accessorType": "relation",
      "key": "dynamicApprovalValues",
      "filter": ["dynamicApproval", "${dropdown_dynamicApproval}"]
  },`;
    return code;
};
blocklyJavascript.javascriptGenerator.forBlock.ignoreBlank_parameter = function (block, generator) {
    const value_ignoreBlank = block.getFieldValue('ignoreBlank') == 'TRUE' ? 'true' : 'false';
    return `"ignoreBlank": ${value_ignoreBlank}, `;
};
blocklyJavascript.javascriptGenerator.forBlock.asset_name_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "name"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.asset_assetType_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "assetType"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.asset_attributes_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "attributes"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.asset_childs_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "childs"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.asset_children_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "children"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.asset_links_accessor = function (block, generator) {
    return [
        `{
      "accessorType": "attribute",
      "key": "links"
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_name_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "name"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_priority_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "priority"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_startDate_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "startDate"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_earliestStartDate_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "earliestStartDate"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_endDate_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "endDate"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_latestEndDate_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "latestEndDate"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_quoteEstimLength_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "quoteEstimLength"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_realEstimLength_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "realEstimLength"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_validLength_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "validLength"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_status_name_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "status",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "name"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_status_order_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "status",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "order"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_status_color_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "status",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "color"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_status_statusType_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "status",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "statusType"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_assignedUser_name_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "assignedUser",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "name"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_lastTake_name_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "lastTake",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "name"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_lastTake_comment_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "lastTake",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "comment"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_lastTake_number_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "lastTake",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "number"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_lastTake_estimLength_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "lastTake",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "estimLength"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_lastTake_statusUpdatedAt_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "lastTake",
        "relatedKey": {
            "accessorType": "attribute",
            "key": "statusUpdatedAt"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_takes_name_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "takes",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "name"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_takes_comment_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "takes",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "comment"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_takes_number_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "takes",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "number"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_takes_estimLength_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "takes",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "estimLength"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_takes_statusUpdatedAt_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "takes",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "statusUpdatedAt"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_activities_duration_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "activities",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "duration"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_activities_activityType_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "activities",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "activityType"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_activities_comment_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "activities",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "comment"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.task_activities_date_accessor = function (block, generator) {
    const dropdown_step = block.getFieldValue('step');
    return [
        `{
      "accessorType": "relation",
      "key": "tasks",
      "filter": ["step", "${dropdown_step}"],
      "relatedKey": {
        "accessorType": "relation",
        "key": "activities",
        "filter": ["", "*"],
        "relatedKey": {
            "accessorType": "attribute",
            "key": "date"
        }
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.dynamicApprovalValue_status_accessor = function (block, generator) {
    const dropdown_dynamicApproval = block.getFieldValue('dynamicApproval');
    return [
        `{
      "accessorType": "relation",
      "key": "dynamicApprovalValues",
      "filter": ["dynamicApproval", "${dropdown_dynamicApproval}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "status"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.dynamicApprovalValue_statusUpdatedAt_accessor = function (block, generator) {
    const dropdown_dynamicApproval = block.getFieldValue('dynamicApproval');
    return [
        `{
      "accessorType": "relation",
      "key": "dynamicApprovalValues",
      "filter": ["dynamicApproval", "${dropdown_dynamicApproval}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "statusUpdatedAt"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
blocklyJavascript.javascriptGenerator.forBlock.dynamicApprovalValue_comment_accessor = function (block, generator) {
    const dropdown_dynamicApproval = block.getFieldValue('dynamicApproval');
    return [
        `{
      "accessorType": "relation",
      "key": "dynamicApprovalValues",
      "filter": ["dynamicApproval", "${dropdown_dynamicApproval}"],
      "relatedKey": {
        "accessorType": "attribute",
        "key": "comment"
      }
  }`,
        blocklyJavascript.Order.NONE,
    ];
};
