import api from 'app/core/api/api.js'
import { actionSetProperties } from 'app/libs/reduxSetProperty'

export const types = { ADD: 'ADD', SET_PROPERTY: 'SET_PROPERTY' }

export const setProperties = actionSetProperties(types.SET_PROPERTY)

export const fetchUser = () => async (dispatch) => {
  const user = await api.user()
  const { results: userProjects } = await api.users.projects({ id: user.asset })
  const localUser = {
    ...user,
    projects: userProjects,
  }
  return dispatch(setProperties(localUser))
}
