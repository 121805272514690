import { jsx as _jsx } from "react/jsx-runtime";
import { CellText, CellSelect, CellRichText, CellLink } from 'app/components/Table/Cells';
import { MUIButton } from 'app/components/Form';
import vars from 'app/styles/vars.js';
import { messagesTypes, scopesTypes } from './utils';
import { updateMessage } from 'app/store/reducers/messages';
export default ({ onDelete, dispatch }) => [
    {
        Header: ' ',
        id: 'rows',
        columns: [
            CellText({
                id: 'title',
                readOnly: false,
                Header: 'Title',
                accessor: 'title',
                width: 200,
                actions: () => ['edit', 'copy'],
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { title: newValue })),
                },
            }),
            CellRichText({
                id: 'text',
                readOnly: false,
                Header: 'Text',
                accessor: 'text',
                actions: () => ['edit', 'copy', 'past'],
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { text: newValue })),
                },
            }),
            CellSelect({
                id: 'messageType',
                readOnly: false,
                Header: 'Message Type',
                width: 100,
                actions: () => ['edit', 'copy'],
                accessor: 'messageType',
                options: () => messagesTypes,
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { messageType: newValue })),
                },
            }),
            CellSelect({
                id: 'Scope',
                readOnly: false,
                Header: 'Scope',
                actions: () => ['edit', 'copy'],
                width: 130,
                accessor: 'scope',
                options: () => scopesTypes,
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { scope: newValue })),
                },
            }),
            CellText({
                id: 'startDate',
                readOnly: false,
                inputProps: {
                    type: 'date',
                },
                Header: 'Start Date',
                accessor: 'startDate',
                sortingKey: 'startDate',
                width: 150,
                actions: () => ['edit', 'copy'],
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { startDate: newValue })),
                },
            }),
            CellText({
                id: 'endDate',
                readOnly: false,
                Header: 'End Date',
                inputProps: {
                    type: 'date',
                },
                accessor: 'endDate',
                sortingKey: 'endDate',
                width: 150,
                actions: () => ['edit', 'copy'],
                reduxSave: {
                    bulk: true,
                    reduxAction: (values) => dispatch(updateMessage(values)),
                    getData: (data, newValue) => (Object.assign(Object.assign({}, data), { endDate: newValue })),
                },
            }),
            CellLink({
                id: 'delete',
                readOnly: false,
                Header: 'Delete',
                onClick: (row) => onDelete(row),
                width: 80,
                accessor: () => _jsx(MUIButton, { icon: "trash", bgColor: vars.colors.red }),
            }),
        ],
    },
];
